<template>
  <div class="mine">
    <div class="mine_aa">
      个人中心
    </div>
  </div>

  <div class="mine_bb">
    <div class="avatar-container_mine">
      <div class="mine_inner">
        <img :src="avatarUrl" alt="头像" class="avatar_mine"
          />
        <div class="camera-icon_mine" @click.stop="onCameraClick">
          <img :src="cameraIconUrl" alt="摄影图标"  />
        </div>
      </div>

    </div>
    <el-form :model="form" label-width="auto" style="max-width: 600px">
      <el-form-item label="姓名">
        <el-input v-model="form.name" />
      </el-form-item>
      <el-form-item label="性别">
        <el-radio-group v-model="form.gender">
          <el-radio value="1">男</el-radio>
          <el-radio value="2">女</el-radio>
        </el-radio-group>

      </el-form-item>
      <el-form-item label="联系电话">
        <el-input v-model="form.phone" />
      </el-form-item>
      <el-form-item label="联系邮箱">
        <el-input v-model="form.email" />
      </el-form-item>
      <el-form-item label="职务">
        <el-input v-model="form.title" />
      </el-form-item>
      <el-form-item label="工作单位">
        <el-input v-model="form.company" />
      </el-form-item>
      <el-form-item label="权限状态">
        <span>{{ form.quanxian }}</span>
      </el-form-item>
    </el-form>
    <el-button type="primary" class="mine_submit" @click="saveMessage">更新基本信息</el-button>
  </div>
  <input type="file" ref="fileinput" @change="onFileSelected" style="display: none" accept="image/*" />
</template>

<script>
import "../css/mine.css"
export default {
  name: 'MineA',
  props: {
    msg: String
  },


}
</script>
<script setup>
import { reactive, onMounted, getCurrentInstance, ref } from 'vue'
import handleError from '../config/HandleError.js'
import { getCookie } from '../store/cookieUtil.js';
import {formatDate} from "../config/formatDate.js"
const user = JSON.parse(sessionStorage.getItem("user"))
const { proxy } = getCurrentInstance()
const avatarUrl = ref(require('@/img/really2.svg'))
const cameraIconUrl = ref(require('@/img/camera.svg'))
const fileinput=ref(null)
const form = reactive({
  name: '',
  gender: '',
  phone: '',
  email: '',
  title: '',
  company: '',
  quanxian: '',
})
onMounted(async () => {
  const urldata = new URLSearchParams();

  urldata.append('Account', user.emailname);
  urldata.append('Token', getCookie('token'));
  await new proxy.$request(proxy.$urls.m().GetAccountMessage, urldata).modepost().then(res => {

    if (!res) return;
    if (res.status != 200) {
      new proxy.$tips(res.data.message, "warning").Mess_age()
      return;
    }
    else {
      if (res.data && res.data.Error == 0) {
        form.name = res.data.Name;
        form.gender =String(res.data.Sex);
        form.phone = res.data.Phone;
        form.email = res.data.Mail;
        form.title = res.data.Post;
        form.company = res.data.WorkUnit;
        form.quanxian = res.data.VipState + "(有效期：" + formatDate(res.data.VIPStateStartTime) + "-" + formatDate(res.data.VIPStateEndTime) + ")"

      }
      else {
        const errorMessage = handleError(res);
        new proxy.$tips(errorMessage, "error").Mess_age();
      }
    }
  })
})
const saveMessage = async () => {
  const urldata = new URLSearchParams();
  urldata.append('account', user.emailname);
  urldata.append('Token', getCookie('token'));
  urldata.append('Name', form.name);
  urldata.append('Sex', form.gender);
  urldata.append('Post', form.title);
  urldata.append('WorkUnit', form.company);
  urldata.append('Phone', form.phone);
  urldata.append('Mail', form.email);

  await new proxy.$request(proxy.$urls.m().ModifyAccountMessage, urldata).modepost().then(res => {

    if (!res) return;
    if (res.status != 200) {
      new proxy.$tips(res.data.message, "warning").Mess_age()
      return;
    }
    else {
      if (res.data && res.data.Error == 0) {
        form.name = res.data.Name;
        form.gender =String(res.data.Sex) ;
        form.phone = res.data.Phone;
        form.email = res.data.Mail;
        form.title = res.data.Post;
        form.company = res.data.WorkUnit;
        new proxy.$tips("修改成功", "success").Mess_age();

      }
      else {
        const errorMessage = handleError(res);
        new proxy.$tips(errorMessage, "error").Mess_age();
      }
    }
  })
}
const onCameraClick = () => {
  fileinput.value.click()
}
const onFileSelected=(event)=>{
  const file = event.target.files[0]; // 获取选择的第一个文件
  avatarUrl.value=URL.createObjectURL(file);
}
</script>
<style></style>