const transformToReactiveStructure = (nodes) => {
    let result = [];
    let i=0;
    // 遍历所有节点，并建立嵌套层次关系
    nodes.slice(1).forEach(node => {
        const extras = node['extras'];
        if (extras != null) {
            const category = extras['categoryName'];
            const level = extras['level'];
            const familyName = extras['familyName'];
            const name = extras['name'];
            const elementID = extras['elementID'];
            const elevation = extras['elevation'];
            // 找到或创建 level 级别
            let levelNode = result.find(item => item.label === level);
            if (!levelNode) {
                levelNode = { label: level, children: [], modelShowflag: true ,_id:i++ };
                result.push(levelNode);
            }
            // 找到或创建 category 级别
            let categoryNode = levelNode.children.find(item => item.label === category);
            if (!categoryNode) {
                categoryNode = { label: category, children: [], modelShowflag: true ,_id:i++};
                levelNode.children.push(categoryNode);
            }
            // 找到或创建 name 级别
            // let nameNode = categoryNode.children.find(item => item.label === name);
            // if (!nameNode) {
            //   // eslint-disable-next-line
            //   nameNode = { label: name, isLeaf :true,modelInfo:extras};
            //   categoryNode.children.push(nameNode);
            // }

            // 添加 name 作为 children 的一个叶子结点
            const newData = {
                "族类型": category,
                "构件ID": elementID,
                "标高": elevation.toFixed(3),
                "族名称": familyName,
                "楼层": level,
                "构件名称": name
            };
            addDataToParameters(extras.Parameters, newData)//将基础数据加到Parameters里面
            const nameplus = node['name'];
            let nameNode = { label: name, isLeaf: true, modelInfo: extras.Parameters, modelShowflag: true,name:nameplus,_id:i++ };
            categoryNode.children.push(nameNode)
        }

    });

    return result;


}
const addDataToParameters=(targetArray, newData) =>{
    // 检查是否目标数组存在
    if (!Array.isArray(targetArray)) {
        throw new Error('目标参数数组必须是一个数组');
    }

    // 创建新的参数组
    const newParameters = {
        GroupName: "基础数据",
        Parameters: []
    };

    // 将新数据的每个字段转化为参数对象
    for (const key in newData) {
        if (Object.prototype.hasOwnProperty.call(newData, key)) {
            newParameters.Parameters.push({
                name: key,
                value: newData[key]
            });
        }
    }

    // 将新的参数组添加到目标数组第一个的位置
    targetArray.unshift(newParameters);
}
export default transformToReactiveStructure
