<template>
    <div id="notice_outter" v-if="notice_tableData.arr.length">
        <el-table :data="notice_tableData.arr" style="width: 100%" @row-click="handleRowClick"
            :row-class-name="tableRowClassName">
            <el-table-column fixed prop="InformType" label="消息类型" :width="getDynamicWidth(250)" align="center">
                <template #default="{ row }">
                    <span>
                        {{ informtype(row.InformType) }}
                    </span>
                </template>
            </el-table-column>
            <el-table-column prop="Content" label="内容" :width="getDynamicWidth(722)" align="center" />
            <el-table-column prop="BeinviteTime" label="时间" :width="getDynamicWidth(370)" align="center">
                <template #default="{ row }">
                    <span>
                        {{ formatDate(row.BeinviteTime) }}
                    </span>
                </template></el-table-column>
            <el-table-column fixed="right" label="操作" align="center">
                <template #default="{ row }">
                    <template v-if="row.InformType == 0 && row.IsRead == false">
                        <el-button link type="primary" size="small" @click.stop="handleAccept(row)">
                            接受
                        </el-button>
                        <el-button link type="danger" size="small" @click.stop="handleReject(row)">
                            拒绝
                        </el-button>
                    </template>
                </template>
            </el-table-column>
        </el-table>
    </div>
    <div id="notice_outter" v-else>
        <el-empty description="暂无消息" class="empty_outter" :image="emptyimageurl" />
    </div>
    <el-dialog v-model="notice_confirm" :title="clickrow.InformType == 0?'项目详情':'任务详情' " class="notice_detail_dialog" :before-close="handleClose" v-if="clickrow"
        :close-on-click-modal="false">
        <div class="detailtype_0" v-if="clickrow.InformType == 0">
            <div class="projectimage">
                <el-image :src="project_datail.imageUrl" :fit="fit" />
            </div>

            <el-descriptions title="" column=2 >
                <el-descriptions-item label="项目类型 :"  label-class-name="my-label_first"
      class-name="my-label_first">
                    {{ project_datail.CivilType }}</el-descriptions-item>
                <el-descriptions-item label="项目面积 :" >{{ project_datail.CivilSquare }}</el-descriptions-item>
                <el-descriptions-item label="项目名称 :">{{ project_datail.CivilName }}</el-descriptions-item>
                <el-descriptions-item label="客户编号 :">{{ project_datail.CivilInternalNumber }} </el-descriptions-item>

                <el-descriptions-item></el-descriptions-item>
                <el-descriptions-item label="开工日期 :">{{ project_datail.CommenceDate }}</el-descriptions-item>
                <el-descriptions-item label="竣工日期 :">{{ project_datail.CompletionDate }}</el-descriptions-item>
            </el-descriptions>
            <el-descriptions column=1>
                <el-descriptions-item label="建设单位 :">{{ project_datail.ConstructionCompany
                    }}</el-descriptions-item>
                <el-descriptions-item label="设计单位 :">{{ project_datail.DesignCompany
                    }}</el-descriptions-item>
                <el-descriptions-item label="监理单位 :">{{ project_datail.SupervisionCompany
                    }}</el-descriptions-item>
                <el-descriptions-item label="施工单位 :">{{ project_datail.ConstructionContractor
                    }}</el-descriptions-item>
                <el-descriptions-item label="备注 :">{{ project_datail.Description }}</el-descriptions-item>
                <el-descriptions-item label="项目地址 :">{{ project_datail.CivilArea }}
                </el-descriptions-item>
            </el-descriptions>
        </div>
        <div class="detailtype_1" v-else>
            <el-descriptions title="" column="2">
                <el-descriptions-item label="任务编号 :" label-class-name="my-label" class-name="my-content">
                    {{ project_datail_rw.TaskCode }}</el-descriptions-item>
                <el-descriptions-item label="任务名称 :">{{ project_datail_rw.TaskName }}</el-descriptions-item>
                <el-descriptions-item label="发布者 :">{{ project_datail_rw.creatorName }} </el-descriptions-item>
                <el-descriptions-item label="发布时间 :">{{ project_datail_rw.createdTime }}</el-descriptions-item>
                <el-descriptions-item label="截止时间 :">{{ project_datail_rw.deadline }}
                </el-descriptions-item>
                <el-descriptions-item label="任务内容 :">{{ project_datail_rw.description }}</el-descriptions-item>
                <el-descriptions-item label="参与成员 :">{{ project_datail_rw.accountinfos }}</el-descriptions-item>
            </el-descriptions>
        </div>

        <template #footer>
            <div class="dialog-footer">
                <template v-if="clickrow.InformType == 0 && clickrow.IsRead == false">
                    <el-button link type="primary" size="small" @click.stop="handleAccept(clickrow)">
                        接受
                    </el-button>
                    <el-button link type="danger" size="small" @click.stop="handleReject(clickrow)">
                        拒绝
                    </el-button>
                </template>
            </div>
        </template>
    </el-dialog>
</template>
<script>
import "../css/notice.css";
export default {
    name: "NoticeItem",
};
</script>
<script setup>
import {
    reactive,
    ref,
    onMounted,
    defineProps,
    watchEffect,
    computed,
    getCurrentInstance,
} from "vue";
import { ElMessageBox } from "element-plus";
import { formatDate, formatDate_simple } from "../config/formatDate.js";
import { useStore } from "vuex";
import { getCookie } from "../store/cookieUtil.js";
import handleError from "../config/HandleError.js";
import eventBus from "../js/eventBus.js";
import projectType from '../config/projecttype.js'
import fileConvert from '@/js/fileConvert.js';
const fileconvert = new fileConvert();
const projecttype = new projectType();
const user = JSON.parse(sessionStorage.getItem("user"));
const props = defineProps({
    msg: String,
    noticelist: {
        type: Array,
        default: () => [],
    },
});
const { proxy } = getCurrentInstance();
const emptyimageurl = require("@/img/暂无任务.svg");
const store = useStore();
const notice_confirm = ref(false);
const notice_tableData = reactive({
    arr: [],
});
const project_datail = reactive({
    CivilArea: "河北省唐山市开平区1",
    CivilInternalNumber: "111",
    CivilName: "111",
    CivilSquare: 11,
    CivilType: 0,
    CommenceDate: 1736352000000,
    CompletionDate: 1737561600000,
    ConstructionCompany: "1",
    ConstructionContractor: "1",
    Description: "1",
    DesignCompany: "1",
    imageUrl: "D:\\Uploads\\111\\111oGh1urtYqk.png",
    SupervisionCompany: "1"

});
const project_datail_rw = reactive({
    TaskName: "",
    TaskCode: "",
    CivilName: "1",
    creatorName: "",
    createdTime: 1736352000000,
    deadline: 1737561600000,
    description: "1",
    accountinfos: "1",
});
onMounted(() => {
    notice_tableData.arr = props.noticelist.slice().sort((a, b) => new Date(b.BeinviteTime) - new Date(a.BeinviteTime)); 
});
const getDynamicWidth = (px) => {
    // 根据窗口的宽度动态计算列宽
    const ratio = window.innerWidth / 2560; // 假设 1920 是你设计的基准宽度
    return Math.max(px * ratio, 10) + "px"; // 保证最小宽度不小于原始值的 80%
};
const prolisttype = computed(() => store.state.noticeitemStatus);
watchEffect(() => {
    if (prolisttype.value == "second") {
        notice_tableData.arr = props.noticelist
            .filter(
                (item) => item.IsRead == false
            )
            .sort((a, b) => new Date(b.BeinviteTime) - new Date(a.BeinviteTime)); // 按日期从新到旧排序
    } else if (prolisttype.value == "thired") {
        notice_tableData.arr = props.noticelist.filter(
            (item) => item.IsRead == true
        ).sort((a, b) => new Date(b.BeinviteTime) - new Date(a.BeinviteTime)); // 按日期从新到旧排序;
    } else {
        // 使用 slice() 创建 noticelist 的副本
        notice_tableData.arr = props.noticelist.slice().sort((a, b) => new Date(b.BeinviteTime) - new Date(a.BeinviteTime)); // 按日期从新到旧排序;
    }
});
const informtype = (type) => {
    switch (type) {
        case 1:
            return "任务邀请";
        case 0:
            return "项目邀请";
    }
};
const handleAccept = (row) => {
    ElMessageBox.confirm("确认加入此项目?", "提示", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
        roundButton: true,
    }).then(async () => {
        await JoinBIMARintergrationInform(row._id, true);
        await ReadEvent(row);

    });
    notice_confirm.value = false;
};
const handleReject = (row) => {
    ElMessageBox.confirm("确认拒绝加入此项目?", "提示", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
        roundButton: true,
    }).then(async () => {
        await JoinBIMARintergrationInform(row._id, false);
        await ReadEvent(row);
    });
    notice_confirm.value = false;
};
const tableRowClassName = ({ row }) => {
    if (!row.IsRead) {
        return "read-row";
    }
    return "";
};
const JoinBIMARintergrationInform = async (id, flag) => {
    const urldata = new URLSearchParams();
    urldata.append("Account", user.emailname);
    urldata.append("Token", getCookie("token"));
    urldata.append("InformId", id);
    urldata.append("isJoin", flag);
    const res = await new proxy.$request(
        proxy.$urls.m().JoinBIMARintergrationInform,
        urldata
    ).modepost();
    console.log(res);
    if (!res) return;
    if (res.status != 200) {
        new proxy.$tips(res.data.message, "warning").Mess_age();
        return;
    } else {
        if (res.data && res.data.Error == 0) {
            new proxy.$tips("操作成功", "success").Mess_age();
            eventBus.emit("decrement");
            eventBus.emit("loadData");
        } else {
            const errorMessage = handleError(res);
            new proxy.$tips(errorMessage, "error").Mess_age();
        }
    }
};
const clickrow = ref();
const handleRowClick = async (row) => {
    clickrow.value = row;
    notice_confirm.value = true;
    // if (row.InformType == 0) {//任务邀请
    //     return;
    // }
    const urldata = new URLSearchParams();
    urldata.append("Account", user.emailname);
    urldata.append("Token", getCookie("token"));
    urldata.append("Id", row.InfomId);
    urldata.append("Type", row.InformType);
    const res = await new proxy.$request(
        proxy.$urls.m().GetSpecificInform,
        urldata
    ).modepost();
    if (!res) return;
    if (res.status != 200) {
        new proxy.$tips(res.data.message, "warning").Mess_age();
        return;
    } else {
        if (res.data && res.data.Error == 0) {
            console.log(res)
            //项目详情
            if (row.InformType == 0) {
                const newDetails = res.data.BIMARIntegrationInfosProto;
                project_datail.CivilArea = newDetails.CivilArea || project_datail.CivilArea; // 如果源数据有 'Area'，更新；否则保持原值
                project_datail.CivilInternalNumber = newDetails.CivilInternalNumber || project_datail.CivilInternalNumber;
                project_datail.CivilName = newDetails.NaCivilNameme || project_datail.CivilName;
                project_datail.CivilSquare = newDetails.CivilSquare || project_datail.CivilSquare;
                project_datail.CivilType = projecttype.getLabel(newDetails.CivilType) || project_datail.CivilType;
                project_datail.CommenceDate = formatDate_simple(newDetails.CommenceDate) || project_datail.CommenceDate;
                project_datail.CompletionDate = formatDate_simple(newDetails.CompletionDate) || project_datail.CompletionDate;
                project_datail.ConstructionCompany = newDetails.ConstructionCompany || project_datail.ConstructionCompany;
                project_datail.ConstructionContractor = newDetails.ConstructionContractor || project_datail.ConstructionContractor;
                project_datail.Description = newDetails.Description || project_datail.Description;
                project_datail.DesignCompany = newDetails.DesignCompany || project_datail.DesignCompany;
                project_datail.imageUrl = "https://bim.bfine-tech.com" + fileconvert.convertWindowsPathToUnixPath(newDetails.imageUrl);
                project_datail.SupervisionCompany = newDetails.SupervisionCompany || project_datail.SupervisionCompany;
            }
            //任务详情
            else {
                const newDetails = res.data.ProjectTaskinfo;
                project_datail_rw.TaskName = newDetails.TaskName;
                project_datail_rw.TaskCode = newDetails.TaskCode;
                project_datail_rw.creatorName = newDetails.creatorName;
                project_datail_rw.createdTime = formatDate_simple(newDetails.createdTime);
                project_datail_rw.deadline = formatDate_simple(newDetails.deadline);
                project_datail_rw.description = newDetails.description;
                project_datail_rw.accountinfos = newDetails.accountinfos.join('、');
            }
        } else {
            const errorMessage = handleError(res);
            new proxy.$tips(errorMessage, "error").Mess_age();
        }
    }

    await ReadEvent(row);
};
const ReadEvent = async (row) => {
    if (!row.IsRead) {
        //未读
        //发送请求
        const urldata = new URLSearchParams();
        urldata.append("Account", user.emailname);
        urldata.append("Token", getCookie("token"));
        urldata.append("InformId", row._id);
        const res = await new proxy.$request(
            proxy.$urls.m().ReadInforms,
            urldata
        ).modepost();
        console.log(res);
        if (!res) return;
        if (res.status != 200) {
            new proxy.$tips(res.data.message, "warning").Mess_age();
            return;
        } else {
            if (res.data && res.data.Error == 0) {
                new proxy.$tips("操作成功", "success").Mess_age();
                eventBus.emit("decrement");
                eventBus.emit("loadData");
            } else {
                const errorMessage = handleError(res);
                new proxy.$tips(errorMessage, "error").Mess_age();
            }
        }
    }
};
</script>
