<template>
    <section>
        <div class="title">
            <img src="../img/72-2.png" alt="">
            <a class="linklogin" @click="loginplus">立即登录</a>
        </div>

        <div action="" id="login-forget">
            <div class="one_inner">
                <div class="one_passwordtext">忘记密码</div>
                <el-input v-model="emailname" type="text" placeholder=" 请输入手机号" class="register_email" />
                <div class="mid">
                    <el-input v-model="yanzhen" placeholder="请输入验证码" class="register_verify_one" />
                    <el-button text type="default" id="checkcode" @click="checkrandom">{{ num1 }} &ensp;{{ operator }}
                        &ensp;{{ num2 }}</el-button>
                </div>
                <el-checkbox v-model="checked_xieyi" label="记住密码" size="small" class="agree">
                    <label for="agree" class="a">我同意
                        <a class="xieyi" href="/serviceAgreement.htm" target="_blank">《彼凡服务协议》</a>和<a class="xieyi"
                            href="/privacyAgrement.htm" target="_blank">《彼凡隐私协议》</a></label>
                </el-checkbox>
                <br>
                <el-button type="primary" @click="forgetsubmit" class="register_submit_one">下一步</el-button>

            </div>
        </div>
    </section>
</template>
<script>
import '../css/forget.css'
import { ref,getCurrentInstance } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import handleError from '../config/HandleError.js'
export default {
    name: 'ForgetA',
    props: {
        msg: String
    },
    setup() {
        const { proxy } = getCurrentInstance()
        const num1 = ref(Math.floor(Math.random() * 10));
        const num2 = ref(Math.floor(Math.random() * 10));
        const operators = ['+', '-', '*'];
        const yanzhen = ref('');
        const router = useRouter();
        const store = useStore()
        const emailname = ref('');
        const checked_xieyi = ref(false)
        const operator = ref(operators[Math.floor(Math.random() * operators.length)]);

        const forgetsubmit = async () => {
            // var atpos = emailname.value.indexOf("@");
            // var dotpos = emailname.value.lastIndexOf(".");
            if (emailname.value == "" || yanzhen.value == "") {
                alert("表单不能为空")
                return;
            }
            else if (checked_xieyi.value == false) {
                alert("请阅读并勾选彼凡服务协议和彼凡隐私协议");
                return false;
            }
            // if (atpos < 1 || dotpos < atpos + 2 || dotpos + 2 >= emailname.value.length) {
            //     alert("不是一个有效的e-mail地址");
            //     return false;
            // }
            // else if(emailname.value==){
            //     alert("邮箱不能为空");
            //     return false;
            // }
            let result = 0;
            switch (operator.value) {
                case '+':
                    result = num1.value + num2.value;
                    break;
                case '-':
                    result = num1.value - num2.value;
                    break;
                case '*':
                    result = num1.value * num2.value;
                    break;
                // case '/':
                //     result = num1.value / num1.value;
                //     break;
                default:
                    break;

            }
            if (yanzhen.value == result) {
                const res = await getcode();
                if (res != null) {
                    router.push({
                        name: 'forgettwo'
                    });
                    store.dispatch('emailfun', emailname)
                }


            }
            else {
                alert("验证码错误");
                checkrandom();
                return false;
            }

        }
        const checkrandom = () => {
            num1.value = Math.floor(Math.random() * 10);
            num2.value = Math.floor(Math.random() * 10);
            operator.value = operators[Math.floor(Math.random() * operators.length)];
        }
        const loginplus = () => {
            router.push({
                name: 'login'
            })
        }
        const getcode = async () => {
            const urldata = new URLSearchParams();
            urldata.append('phone', emailname.value);
            const res = await new proxy.$request(proxy.$urls.m().GetCode, urldata).modepost()
            if (!res) return;
            if (res.status != 200) {
                new proxy.$tips(res.data.message, "warning").Mess_age()
                return;
            }
            else {
                if (res.data && res.data.Error == 0) {
                    console.log(res)
                    return res

                }
                else {
                    const errorMessage = handleError(res);
                    new proxy.$tips(errorMessage, "error").Mess_age()

                }



            }
        }
        return {
            num1, num2, operator, forgetsubmit, checkrandom, yanzhen, emailname, checked_xieyi,
            loginplus,getcode
        }
    }
}

</script>
<style scoped></style>