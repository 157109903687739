<template>
    <div class="memberbody">
        <div class="member_a">
            <div class="mem">
                <el-tabs class="mem_demo-tabs" @tab-change="member_tabclick" v-model="mem_activeName" >
                    <el-tab-pane label="项目成员" name="first">
                        <div class="mem_title">
                            <el-input v-model="keyvalue" placeholder="用户名/姓名" size="default" class="mem_input"
                                :suffix-icon="Search" @change="member_query" />
                            <el-select v-model="rolevalue" placeholder="任务角色" size="default" class="mem_input_role"
                                @change="member_role_query">
                                <el-option v-for="item in role_options" :key="item.value" :label="item.label"
                                    :value="item.value" />
                            </el-select>

                        </div>
                        <el-table :data="member_tableData.arr" style="width: 100% ;margin-top: 5px" class="member_table_outter" border>
                            <el-table-column prop="AcountName" label="用户名" :width="getDynamicWidth(300)"
                                align="center" />
                            <el-table-column prop="Phone" label="手机号" :width="getDynamicWidth(300)" align="center" />
                            <el-table-column prop="Name" label="姓名" :width="getDynamicWidth(300)" align="center" />
                            <el-table-column prop="RoleName" label="项目角色" :width="getDynamicWidth(450)"
                                align="center" />
                            <el-table-column prop="JoinTime" label="加入项目时间" :width="getDynamicWidth(250)"
                                align="center">
                                <template #default="{ row }">
                                    <span>
                                        {{ formatDate(row.JoinTime) }}
                                    </span>
                                </template>
                            </el-table-column>
                            <el-table-column fixed="right" label="操作" align="center">
                                <template #default="{ row }">
                                    <el-button link type="primary" size="primary" @click="editMem_handleClick(row)">
                                        编辑
                                    </el-button>
                                    <el-button link type="danger" size="primary"
                                        @click="delete_member(row)">删除</el-button>
                                </template>
                            </el-table-column>
                        </el-table>

                    </el-tab-pane>
                    <el-tab-pane label="角色配置" name="second">
                        <div class="mem_title">
                            <el-input v-model="keyvalue_role" placeholder="角色名" size="default" class="mem_input"
                                :suffix-icon="Search" @change="queryRole" />
                        </div>
                        <el-table :data="role_tableData.arr" style="width: 100% ;margin-top: 5px"  class="member_table_outter" border>
                            <el-table-column prop="RoleName" label="角色名称" :width="getDynamicWidth(300)"
                                align="center" />
                            <el-table-column prop="RoleNum" label="关联成员数" :width="getDynamicWidth(300)"
                                align="center" />
                            <el-table-column prop="CreateTime" label="创建日期" :width="getDynamicWidth(250)"
                                align="center">
                                <template #default="{ row }">
                                    <span>
                                        {{ formatDate(row.CreateTime).split(" ")[0] }}
                                    </span>
                                </template></el-table-column>
                            <el-table-column prop="role_quanxian" label="权限" :width="getDynamicWidth(575)"
                                align="center">
                                <template #default="{ row }">
                                    <el-link type="primary" @click="role_detail_click(row)">详情</el-link>
                                </template>
                            </el-table-column>
                            <el-table-column fixed="right" label="操作" align="center">
                                <template #default="{ row }">
                                    <el-button link type="primary" size="primary" @click="editrole(row)">
                                        配置
                                    </el-button>
                                    <el-button link type="danger" size="primary"
                                        @click="deleterole(row._id)">删除</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-tab-pane>
                </el-tabs>
            </div>
            <div class="cy">
                <el-button text type="primary" @click=cyinvitclick class="member_invite"
                    v-if="cy_invite === 'first'">成员邀请</el-button>
                <el-button type="primary" @click="addrole_dialog" v-else>新增角色</el-button>
            </div>
        </div>

    </div>
    <el-dialog v-model="cyinvite" :title="cy_text" width="500" venter @open="getUserList" class="role_detail"
        :close-on-click-modal="false" @close="handleDialogClose" >
        <el-form :model="cyform" :rules="cy_text == '编辑项目角色' ? {} : rules" ref="cyinviteForm">
            <el-form-item label="用户账号" :label-width="formLabelWidth" prop="emailname">
                <el-input v-model="cyform.emailname" autocomplete="off" placeholder="请输入邀请用户账号" required
                    :disabled="isDisabled" />
            </el-form-item>
            <el-form-item label="选择角色" :label-width="formLabelWidth" prop="quanxian">
                <el-select v-model="cyform.quanxian" placeholder="请选择" @change="handleSelectChange">
                    <el-option v-for="item in role_options" :key="item.value" :label="item.label" :value="item.value" />
                </el-select>
            </el-form-item>
        </el-form>
        <template #footer>
            <div class="dialog-footer">
                <el-button @click="cyinvitecancel">取消</el-button>
                <el-button type="primary" @click="inviteclick">
                    提交
                </el-button>
            </div>
        </template>
    </el-dialog>
    <el-dialog v-model="addrole" title="角色" width="900" venter @open="rolepopover" class="role_detail"
        @close="handleDialogClose_role" :close-on-click-modal="false">
        <el-form :model="roleform" :rules="rules2" ref="addroleForm">
            <el-form-item label="角色名称" :label-width="formLabelWidth" prop="rolename">
                <el-input v-model="roleform.rolename" placeholder="角色名称" />
            </el-form-item>
            <div class="role_title">权限配置</div>
            <el-tree :data="allroledata" show-checkbox node-key="id" :expand-on-click-node="false" ref="RoletreeRef"
                default-expand-all :props="defaultProps" />

        </el-form>
        <template #footer>
            <div class="dialog-footer">
                <el-button @click="addrolecancel">取消</el-button>
                <el-button type="primary" @click="addroleclick">
                    提交
                </el-button>
            </div>
        </template>
    </el-dialog>
    <el-dialog v-model="detailpopover" title="详情" width="900" venter @open="detailpopoveropen" class="role_detail" :close-on-click-modal="false">
        <el-tree style="max-width:1100px" :data="allroledata" show-checkbox node-key="id" :expand-on-click-node="false"
            ref="detail_RoletreeRef" :props="defaultProps_detail" default-expand-all />
        <template #footer>
            <div class="dialog-footer">
                <el-button type="primary" @click="closedetailpopover">
                    确定
                </el-button>
            </div>
        </template>
    </el-dialog>
</template>
<script setup>
import { ref, reactive, watch, getCurrentInstance, onMounted, computed } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import { getCookie } from '../store/cookieUtil.js';
import handleError from '../config/HandleError.js'
import {formatDate} from "../config/formatDate.js"
import { Search } from '@element-plus/icons-vue'
import handleAutoCheck from '@/js/handleAutoCheck.js';
let flagmember = null;
let add = null;
const { proxy } = getCurrentInstance()
const mem_activeName = ref('first')
const cy_invite = ref('first')
const RoletreeRef = ref(null)
const detail_RoletreeRef = ref(null)
const keyvalue = ref('')
const rolevalue = ref('')
const cyinvite = ref(false)
const addrole = ref(false)
const detailpopover = ref(false)
const keyvalue_role = ref('')
const isDisabled = ref(false)
const projectinfo = JSON.parse(sessionStorage.getItem("projectinfo"))
const role_options = reactive([])
const cyform = reactive(
    {
        emailname: "",
        quanxian: ""
    },
)
const user = JSON.parse(sessionStorage.getItem('user'))
const member_tableData = reactive({
    arr: []
})
const cy_text = ref("成员邀请")
const role_tableData = reactive({ arr: [] })
const rules = reactive(
    {
        emailname: [
            {
                required: true, message: "账号不能为空", trigger: "blur"
            },
            // {
            //     type: "email", message: "请输入正确的邮件名", trigger: "blur"
            // },
        ],
        quanxian: [
            {
                required: true, message: "角色不能为空", trigger: "change"
            }
        ]
    }
);
const rules2 = reactive(
    {
        rolename: [
            {
                required: true, message: "角色名不能为空", trigger: "blur"
            },
        ]
    }
);
const cyinvitclick = () => {
    cyinvite.value = true;
    member_detail = null;
    flagmember = 0;
    cy_text.value = "成员邀请"
}
const defaultProps = {
    children: 'children',
    label: 'label', // 用于展示的名称
    value: 'value', // 用于 URL 的值
    class: customNodeClass
};
const defaultProps_detail = {
    children: 'children',
    label: 'label', // 用于展示的名称
    value: 'value', // 用于 URL 的值
    class: customNodeClass_detail,
    disabled: (data, node) => {
        return true; // 返回 true 禁用所有节点
    }
};
const member_tabclick = async (tab) => {
    if (tab === 'first') {
        await refreshMember()
    } else {
        await refreshRole()
    }
}
const member_query = () => {
    member_tableData.arr = Memberlist.filter(item => {
        return item.AcountName.toLowerCase().includes(keyvalue.value.toLowerCase())
    })
}
const member_role_query = (newValue) => {
    const selectedOption = role_options.find(option => option.value === newValue);
    const selectedLabel = selectedOption ? selectedOption.label : '';
    member_tableData.arr = Memberlist.filter(item => {
        return item.RoleName.toLowerCase().includes(selectedLabel.toLowerCase());
    });
};
const cyinviteForm = ref(null)
const inviteclick = async () => {

    await cyinviteForm.value?.validate(async (valid) => {
        if (!valid) {
            ElMessage.error("请完善表单，再提交！");
            return false;
        }
        let res = null;
        const urldata = new URLSearchParams();
        urldata.append('Token', getCookie('token'));
        urldata.append('Account', user.emailname);
        urldata.append('ProjectId', projectinfo._id);
        urldata.append('BeUserName', cyform.emailname);
        urldata.append('AuthorityId', cyform.quanxian);
        urldata.append('RoleName', selectedLabel.value);
        if (flagmember == 1) {
            //修改
            console.log(cyform)
            res = await new proxy.$request(proxy.$urls.m().ModifyUser, urldata).modepost();

        }
        else {
            //添加
            res = await new proxy.$request(proxy.$urls.m().AddUser, urldata).modepost();
        }
        if (!res) return;
        console.log(res)
        if (res.status != 200) {
            new proxy.$tips(res.data.message, "warning").Mess_age()
            return;
        }
        else {
            if (res.data && res.data.Error == 0) {
                new proxy.$tips("成功", "success").Mess_age()
                await refreshMember();
                cyinvite.value = false;
            }
            else {
                const errorMessage = handleError(res);
                new proxy.$tips(errorMessage, "error").Mess_age()

            }
        }

    })

    //发送请求
}
const addrole_dialog = () => {
    addrole.value = true;
    add = 0;
}
const selectedLabel = computed(() => {
    const selected = role_options.find(item => item.value === cyform.quanxian);
    return selected ? selected.label : '未选择'; // 如果未选择，返回默认值
});
const cyinvitecancel = () => {
    // if (cyinviteForm.value != null) {
    //     cyinviteForm.value.resetFields()
    // }
    cyinvite.value = false;

}
const addroleForm = ref(null)
const addroleclick = async () => {
    await addroleForm.value?.validate(async (valid) => {
        if (!valid) {
            ElMessage.error("请完善表单信息，再提交！");
            return false;
        }
        const urlParams = {};
        const checkedNodes = RoletreeRef.value.getCheckedNodes();
        const allNodes = getAllNodes(allroledata);
        // 遍历所有节点
        allNodes.forEach((node) => {
            // 如果节点在已选中的节点列表里，则设为 true
            const isChecked = checkedNodes.some(checkedNode => checkedNode.id == node.id);
            urlParams[node.value] = isChecked ? 'true' : 'false';
        });
        const queryString = Object.keys(urlParams)
            .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(urlParams[key])}`)
            .join('&');
        let res = null;
        const urldata = new URLSearchParams();
        urldata.append('Token', getCookie('token'));
        urldata.append('Account', user.emailname);
        urldata.append('RoleName', roleform.rolename);
        // 将 queryString 中的参数逐一添加到 urldata 中
        queryString.split('&').forEach(param => {
            const [key, value] = param.split('=');
            urldata.append(decodeURIComponent(key), decodeURIComponent(value));
        });

        if (add != 1) {
            // urldata.append('RoleName', selectedLabel.value);
            urldata.append('ProjectId', projectinfo._id);
            res = await new proxy.$request(proxy.$urls.m().CreateRole, urldata).modepost();
        }
        else {
           
            urldata.append('AuthorityId', rowdata._id);
            res = await new proxy.$request(proxy.$urls.m().ModifyRole, urldata).modepost();
        }
        if (!res) return;
        console.log(res);
        if (res.status != 200) {
            new proxy.$tips(res.data.message, "warning").Mess_age();
            return;
        }
        if (res.data && res.data.Error === 0) {
            new proxy.$tips('成功', "success").Mess_age();
            await refreshRole();
            addrole.value = false;
        } else {
            const errorMessage = handleError(res);
            new proxy.$tips(errorMessage, "error").Mess_age();
        }

    })

    //发送请求
}
const rolepopover = () => {
    //编辑角色
    if (add == 1) {
        const temp_roledata = allroledata;
        const allNodes = getAllNodes(temp_roledata);
        handleAutoCheck(RoletreeRef, allNodes, rowdata);
        roleform.rolename = rowdata.RoleName;
    }
    else {
        roleform.rolename = "";
        RoletreeRef.value.setCheckedKeys([])
    }
}
let member_detail = null;
const editMem_handleClick = (row) => {
    cyinvite.value = true;
    member_detail = row;
    flagmember = 1;
    console.log(row)
    cy_text.value = "编辑项目角色"
}
const addrolecancel = () => {
    // if (addroleForm.value != null) {
    //     addroleForm.value.resetFields()
    // }
    addrole.value = false;
}
const roleform = reactive(
    {
        rolename: "",
        checkedroles: ["新建文件夹"],
    }
)

watch(() => mem_activeName.value, (newvalue) => {
    cy_invite.value = newvalue;
    console.log(cy_invite.value)
});

onMounted(async () => {
    await refreshMember();
})
const editrole = (row) => {
    addrole.value = true;
    rowdata = row;
    add = 1;
}
const handleDialogClose_role = () => {
    addroleForm.value.clearValidate()
}
const delete_member = (row) => {
    ElMessageBox.confirm(
        '确认删除该成员?',
        '警告',
        {
            confirmButtonText: '确认删除',
            cancelButtonText: '取消',
            type: 'warning',
        }
    )
        .then(async () => {
            const urldata = new URLSearchParams();
            urldata.append('Account', user.emailname);
            urldata.append('Token', getCookie('token'));
            urldata.append('ProjectId', projectinfo._id);
            urldata.append('BeUserName', row.AcountName);
            urldata.append('RolePermissionId', row._id);
            const res = await new proxy.$request(proxy.$urls.m().DeleteUser, urldata).modepost();
            console.log(res)
            if (!res) return;
            if (res.status != 200) {
                new proxy.$tips(res.data.message, "warning").Mess_age()
                return;
            }
            else {
                if (res.data && res.data.Error == 0) {
                    await refreshMember();
                    new proxy.$tips("删除成功", "success").Mess_age()
                }
                else {
                    const errorMessage = handleError(res);
                    new proxy.$tips(errorMessage, "error").Mess_age()
                }
            }
        })
        .catch(() => {
            ElMessage({
                type: 'info',
                message: '删除失败',
            })
        })

}
let Memberlist = null;
const refreshMember = async () => {
    keyvalue.value = "";
    rolevalue.value = ''
    const urldata = new URLSearchParams();
    urldata.append('Account', user.emailname);
    urldata.append('Token', getCookie('token'));
    urldata.append('ProjectId', projectinfo._id);
    const res = await new proxy.$request(proxy.$urls.m().GetUser, urldata).modepost();
    console.log(res);
    if (!res) return;
    if (res.status != 200) {
        new proxy.$tips(res.data.message, "warning").Mess_age()
        return;
    }
    else {
        if (res.data && res.data.Error == 0) {
            Memberlist = res.data.Permissions;
            member_tableData.arr = Memberlist
        }
        else {
            const errorMessage = handleError(res);
            new proxy.$tips(errorMessage, "error").Mess_age()

        }
    }
    await getUserList();


}
let rolelist = null;
const refreshRole = async () => {
    const urldata = new URLSearchParams();
    urldata.append('Account', user.emailname);
    urldata.append('Token', getCookie('token'));
    urldata.append('ProjectId', projectinfo._id);
    const res = await new proxy.$request(proxy.$urls.m().GetRole, urldata).modepost();
    if (!res) return;
    if (res.status != 200) {
        new proxy.$tips(res.data.message, "warning").Mess_age()
        return;
    }
    else {
        if (res.data && res.data.Error == 0) {
            rolelist = res.data.Permissions;
            role_tableData.arr = rolelist;
            console.log(res)
        }
        else {
            const errorMessage = handleError(res);
            new proxy.$tips(errorMessage, "error").Mess_age()

        }
    }
    keyvalue_role.value = ''

}
const deleterole = (id) => {
    ElMessageBox.confirm(
        '此操作一经提交将无法恢复，是否继续？',
        '警告',
        {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
        }
    )
        .then(async () => {
            const urldata = new URLSearchParams();
            urldata.append('Account', user.emailname);
            urldata.append('Token', getCookie('token'));
            urldata.append('ProjectId', projectinfo._id);
            urldata.append('AuthorityId', id);
            const res = await new proxy.$request(proxy.$urls.m().DeleteRole, urldata).modepost();
            if (!res) return;
            console.log(res)
            if (res.status != 200) {
                new proxy.$tips(res.data.message, "warning").Mess_age()
                return;
            }
            else {
                if (res.data && res.data.Error == 0) {
                    new proxy.$tips('删除成功', "success").Mess_age()
                    await refreshRole();
                }
                else {
                    const errorMessage = handleError(res);
                    new proxy.$tips(errorMessage, "error").Mess_age()
                }
            }

        })
        .catch(() => {
            ElMessage({
                type: 'info',
                message: '已取消删除'
            });
        });

}
const customNodeClass = (data, node) => {
    if (Array.isArray(data.children) && data.children.length > 0) {
        return 'has-children1';
    }
};
const handleDialogClose = () => {
    cyinviteForm.value.clearValidate();
}
const customNodeClass_detail = (data) => {
    if (!data) return '';
    const isRootNode = !data.parent; // 如果有 parent 属性，说明不是根节点
    if (isRootNode) {
        // 返回为根节点定义的类
        return 'root-node-class';
    }
};

const queryRole = () => {
    role_tableData.arr = rolelist.filter(item => {
        return item.RoleName.toLowerCase().includes(keyvalue_role.value.toLowerCase())
    })
}
const getAllNodes = (data) => {
    const nodes = [];

    const traverse = (node) => {

        if (node.children) {
            node.children.forEach(traverse);
        }
        else {
            nodes.push(node);
        }
    };

    data.forEach(traverse);
    return nodes;
};
const getUserList = async () => {

    if (member_detail != null) {
        cyform.emailname = member_detail.AcountName;
        cyform.quanxian = member_detail.RoleName;
        isDisabled.value = true;
    }
    else {
        isDisabled.value = false;
        cyform.emailname = "";
        cyform.quanxian = "";
    }
    const urldata = new URLSearchParams();
    urldata.append('Account', user.emailname);
    urldata.append('Token', getCookie('token'));
    urldata.append('ProjectId', projectinfo._id);
    const res = await new proxy.$request(proxy.$urls.m().GetRole,urldata).modepost();
    if(!res) return;
    if (res.status != 200) {
        new proxy.$tips(res.data.message, "warning").Mess_age()
        return;
    }
    else {
        if (res.data && res.data.Error == 0) {
            role_options.length = 0;
            res.data.Permissions.forEach(role => {
                role_options.push({
                    label: role.RoleName,
                    value: role._id
                });
            });
        }
        else {
            const errorMessage = handleError(res);
            new proxy.$tips(errorMessage, "error").Mess_age()

        }
    }


}
const getDynamicWidth = (px) => {
    // 根据窗口的宽度动态计算列宽
    const ratio = window.innerWidth / 2560; // 假设 1920 是你设计的基准宽度
    return Math.max(px * ratio, 10) + 'px'; // 保证最小宽度不小于原始值的 80%
}
let rowdata = null;
const role_detail_click = (row) => {
    detailpopover.value = true;
    rowdata = row;
}
const detailpopoveropen = () => {
    const temp_roledata = allroledata;
    const allNodes = getAllNodes(temp_roledata);
    handleAutoCheck(detail_RoletreeRef, allNodes, rowdata);
}

const closedetailpopover = () => {
    detailpopover.value = false;
}
const allroledata = reactive([
    {
        id: 1,
        label: '彼凡BIM魔术箱工具',
        children: [
            {
                id: 4,
                label: 'AR智慧云平台工具',
                value: 'ARCloundPlatform'

            },
            {
                id: 5,
                label: '建模及深化工具',
                value: 'DivideByTable'
            },
        ],
    },
    {
        id: 2,
        label: '彼凡BIM项目管理平台',
        children: [
            {
                id: 6,
                label: '项目',
                children: [
                    {
                        id: 7,
                        label: '新建项目',
                        value: 'NewProject'
                    },
                ]
            },
            {
                id: 8,
                label: '任务管理',
                children: [
                    {
                        id: 9,
                        label: '新建任务',
                        value: 'NewTask'
                    },
                    {
                        id: 10,
                        label: '删除任务',
                        value: 'DeleteTask'
                    },
                    {
                        id: 11,
                        label: '修改任务',
                        value: 'ModifyTask'
                    },
                    {
                        id: 12,
                        label: '任务回复',
                        value: 'RecoverTask'
                    },
                    {
                        id: 13,
                        label: '上传文件',
                        value: 'UploadFile'
                    },
                    {
                        id: 14,
                        label: '下载文件',
                        value: 'DownloadFile'
                    },
                    {
                        id: 15,
                        label: '删除文件',
                        value: 'DeleteFile'
                    },
                ]
            },
            {
                id: 16,
                label: '文档管理',
                children: [
                    {
                        id: 17,
                        label: '新建文件夹',
                        value: 'NewFolder'
                    },
                    {
                        id: 18,
                        label: '删除文件夹',
                        value: 'DeleteFolder'
                    },
                    {
                        id: 19,
                        label: '修改文件夹',
                        value: 'ModifyFolder'
                    },
                    {
                        id: 20,
                        label: '上传文件',
                        value: 'UploadFile'
                    },
                    {
                        id: 21,
                        label: '下载文件',
                        value: 'DownloadFile'
                    },
                    {
                        id: 22,
                        label: '更新文件',
                        value: 'UploadFile'
                    },
                    {
                        id: 23,
                        label: '移动文件',
                        value: 'MoveFile'
                    },
                ]
            },
            {
                id: 24,
                label: '模型管理',
                value: 'ModelManagement'
            },
            {
                id: 25,
                label: '成本管理',
                value: 'MemberManagement'
            },
            {
                id: 26,
                label: '质量管理',
                value: 'QualityManagement'
            },
            {
                id: 27,
                label: '进度管理',
                value: 'ProgressManagement'
            },
            {
                id: 28,
                label: '成员管理',
                children: [
                    {
                        id: 29,
                        label: '成员邀请',
                        value: 'InviteMember'
                    },
                    {
                        id: 30,
                        label: '成员删除',
                        value: 'DeleteMember'
                    },
                    {
                        id: 31,
                        label: '角色创建',
                        value: 'CreateRole'
                    },
                    {
                        id: 32,
                        label: '角色修改',
                        value: 'ModifyRole'
                    },
                    {
                        id: 33,
                        label: '角色删除',
                        value: 'DeleteRole'
                    },
                ]
            },
        ],
    },
    {
        id: 3,
        label: '彼凡AR智慧云平台',
        children: [
            {
                id: 34,
                label: '测量',
                value: 'Measure'
            },
            {
                id: 35,
                label: '截图',
                value: 'Screenshot'
            },
            {
                id: 36,
                label: '问题报告',
                value: 'IssueReport'
            },
            {
                id: 37,
                label: '模型地图',
                value: 'ModelMap'
            },
            {
                id: 38,
                label: '漫游',
                value: 'Roam'
            },
            {
                id: 39,
                label: '施工模拟',
                value: 'ConstructionSimulation'
            },
            {
                id: 40,
                label: 'VR模式',
                value: 'VRMode'
            },
            {
                id: 41,
                label: 'AR模式',
                value: 'ARMode'
            },
            {
                id: 42,
                label: '构件属性',
                value: 'ComponentProperties'
            },
            {
                id: 43,
                label: '构件树',
                value: 'ComponentTree'
            },

        ],
    },
])
</script>
<script>
import '../css/member.css'
export default {
    name: 'HeaderComp',
    props: {
        msg: String
    },
}
</script>

<style></style>