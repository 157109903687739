<template>

    <el-collapse v-model="activeNames" @change="handleChange_collapse" class="rw_out" v-if="renwuinfo.arr.length">
        <el-collapse-item v-for="(item, index) in renwuinfo.arr" :name="item._id" :key="index">
            <template #title>
                <div :class="['title-bg', !item.status ? 'in-progress' : 'completed']">
                    <div class="renwuseq">{{ item.TaskCode }}</div>
                    <div class="renwumc">{{ item.TaskName }}</div>
                    <!-- <img src="../img/editxm.svg" class="edit-icon" alt=""> -->
                    <el-icon color="#FFFFFF" class="edit-icon" @click.stop="editrenwuplus($event, item)"
                        v-if="!item.status">
                        <Edit />
                    </el-icon>
                    <!-- <el-image class="edit-icon_delete" :src="deleterw"
                        @click.stop="deleterenwuplus($event, item._id)"></el-image> -->
                    <div class="jxz" v-if="!item.status">进行中</div>
                    <div class="jxz" v-else>已完成</div>
                    <el-icon class="item_icon-left">
                        <template v-if="activeNames.includes(item._id)">
                            <CaretBottom />
                        </template>
                        <template v-else>
                            <CaretRight />
                        </template>
                    </el-icon>

                </div>
            </template>
            <div class="time">
                <div class="fbtime"> 发布时间 :&nbsp;&nbsp;{{ formatDate(item.createdTime) }}</div>
                <div class="fbz">发布者 :&nbsp;&nbsp;{{ item.creatorName }}</div>
                <div class="jztime">截止时间 :{{ formatDate(item.deadline) }}</div>
                <div class="operation">
                    <el-dropdown placement="bottom" trigger="click">
                        <img src="../img/detail_rw.svg">
                        <template #dropdown>
                            <el-dropdown-menu>
                                <el-dropdown-item @click="deleterenwuplus($event, item._id)">删除任务</el-dropdown-item>
                                <el-dropdown-item @click="finishAssignment(item)"
                                    v-if="!item.status">完成任务</el-dropdown-item>

                            </el-dropdown-menu>
                        </template>
                    </el-dropdown>
                </div>
            </div>
            <div class="rwdescribe">
                <div class="rwdescribe_text">任务内容：</div>
                <div class="rwdescribe_content">{{ item.description }}</div>
            </div>
            <el-row class="item_filed">
                <el-col :span="4" v-for="(file, index) in item.FileInfos" :key="index" :offset="1">
                    <div class="rwitem_upload" @dblclick="previewFile(file)">
                        <div class="rwitem_submited" :title="file.FileName">
                            <img :src="getImageSrc(file.FileType)">
                            <label>{{ file.FileName }}.{{ getFileTypeByValue(file.FileType).toLowerCase() }}</label>
                        </div>
                        <img src="../img/fjdownload.svg" @click.stop="downloadClick(file)">
                        <img src="../img/fjdelete.svg" v-if="isUserInAccountInfos(item) && !item.status"
                            @click.stop="deleteClick(item, file)">
                    </div>
                </el-col>
            </el-row>
            <div class="inner2">
                <div class="upload_release" v-if="isUserInAccountInfos(item) && !item.status">
                    <el-upload class="upload-demo__release" action="#"
                        :http-request="(uploadFile) => fileupload(uploadFile, item)" :show-file-list="false">
                        <el-button class="fujian">上传附件</el-button>
                    </el-upload>
                    <!-- 附件上传预览 -->
                    <!-- <el-row class="item_el_row__release">
                        <el-col :span="4" v-for="(file, index) in item.FileInfos" :key="index" :offset="1">
                            <div class="rwitem_upload">
                                <div class="rwitem_submited">
                                    <img :src="getImageSrc(file.FileType)">
                                    <label>{{ file.FileName }}</label>
                                </div>
                                <img src="../img/fjdownload.svg" @click="downloadClick(file)">
                                <img src="../img/fjdelete.svg">
                            </div>
                        </el-col>
                    </el-row> -->
                </div>
                <el-table :data="item.Submissioninfo" style="width: 100%" class="datatable">
                    <!-- align="center" -->
                    <el-table-column fixed prop="UserAccount" label="接收者" :width="getDynamicWidth(200)"
                        align="center" />
                    <el-table-column prop="Comments" label="回复" :width="getDynamicWidth(525)" align="center"
                        show-overflow-tooltip />
                    <el-table-column prop="SubmissionTime" label="提交时间" :width="getDynamicWidth(300)" align="center">
                        <template #default="{ row }">
                            <span>
                                {{ formatDate(row.SubmissionTime) }}
                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="fileInfos" label="提交成果" :width="getDynamicWidth(500)" align="center">
                        <template #default="{ row }">
                            <el-col :span="1" v-for="file in row.files" :key="file.FileId" :offset="1"
                                :justify="'center'" style="text-align: left;" class="fileinfo_col">
                                <div class="submitting" @dblclick="previewFile(file)">
                                    <div class="submited" :title="file.FileName">
                                        <img :src="getImageSrc(file.FileType)">
                                        <label>{{ file.FileName }}.{{ getFileTypeByValue(file.FileType).toLowerCase()
                                            }}</label>
                                    </div>
                                    <div class="re" v-if="isUserInAccountInfos(item)">
                                        <el-button link type="primary" @click.stop="downloadClick(file)">下载</el-button>
                                    </div>
                                    <div class="ac" v-else>
                                        <el-button link type="primary">上传</el-button>
                                        <el-button link type="danger">删除</el-button>
                                    </div>
                                </div>
                            </el-col>

                        </template>

                    </el-table-column>
                    <el-table-column fixed="right" label="操作" align="center">
                        <template #default="{ row }">
                            <div v-if="isUserInAccountInfos(item) && !item.status && row.Status == 0">
                                <el-button link type="primary" @click="finishhandleClick(row, item)">完成</el-button>
                                <el-button link type="danger" @click="rejectHandleClick(row, item)">驳回</el-button>
                            </div>
                            <el-button link type="primary" @click="handleClickreply(row, item._id)"
                                v-else-if="!item.status && !isUserInAccountInfos(item)">回复</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>

        </el-collapse-item>
    </el-collapse>
    <el-empty description="暂无任务" v-else class="empty_outter" :image="emptyimageurl" />
    <el-dialog v-model="editrenwu" title="编辑任务" width="1000" venter center round @opened="loadData"
        :close-on-click-modal="false">
        <el-form :model="rwform" ref="rweditRef" :rules="rules">
            <el-form-item label="任务描述" :label-width="formLabelWidth" required prop="describe">
                <el-input v-model="rwform.describe" placeholder="请输入任务描述" />
            </el-form-item>
            <el-form-item label="项目成员" :label-width="formLabelWidth" required>
                <el-select v-model="rwform.chengyuan" placeholder="请选择项目成员" multiple>
                    <template #header>
                        <el-checkbox v-model="checkAll" :indeterminate="indeterminate" @change="handleCheckAll">
                            All
                        </el-checkbox>
                    </template>
                    <el-option v-for="item in xmcyoptions" :key="item.value" :label="item.label" :value="item.value" />
                </el-select>
            </el-form-item>
            <el-form-item label="上传文件" :label-width="formLabelWidth" class="upload_release_label">
                <div class="edit_load_out">
                    <div class="outtop">
                        <el-row class="item_filed_load">
                            <el-col :span="12" v-for="file in rwform.fileList_load" :key="file.FileId" :offset="0">
                                <div class="rwitem_upload">
                                    <div class="rwitem_submited">
                                        <img :src="getImageSrc(file.FileType)">
                                        <label>{{ file.FileName }}</label>
                                    </div>
                                    <img src="../img/fjdelete.svg" v-if="isUserInAccountInfos(rwform)"
                                        @click="removeFile(file.FileId)" style="cursor: pointer;">
                                </div>
                            </el-col>
                        </el-row>
                    </div>
                    <div class="outbottom">
                        <el-upload v-model:file-list="fileList_edit" class="rwupload-demo" action="#" multiple
                            :limit="9" :auto-upload="false">
                            <!-- <el-button type="primary">Click to upload</el-button> -->
                            <el-button class="wenjian"><el-icon>
                                    <Plus />
                                </el-icon>选择文件</el-button>
                            <!-- <template #tip>
                        <div class="rwel-upload__tip">
                            jpg/png files with a size less than 500KB.
                        </div>
                    </template> -->
                        </el-upload>
                    </div>


                </div>

            </el-form-item>
            <el-form-item label="截止日期" required prop="jzdate">
                <el-date-picker v-model="rwform.jzdate" type="datetime" placeholder="请选择" style="width: 100%" />
            </el-form-item>

        </el-form>
        <template #footer>
            <div class="dialog-footer">
                <el-button type="primary" @click="editrenwuclick" :loading="button_loading">保存</el-button>
                <el-button @click="editrenwucancel">
                    取消
                </el-button>
            </div>
        </template>
    </el-dialog>
    <el-dialog v-model="replycontent" title="回复" width="500" venter center round :close-on-click-modal="false">
        <el-form :model="replyform">
            <el-form-item label="文字回复" :label-width="formLabelWidth">
                <el-input v-model="replyform.describe" maxlength="500" style="width: 440px" placeholder="请输入"
                    show-word-limit type="textarea" />
            </el-form-item>

            <el-form-item label="上传文件" :label-width="formLabelWidth">
                <el-upload v-model:file-list="fileList_reply" class="rwupload-demo" action="#" multiple :limit="9"
                    :auto-upload="false">
                    <!-- <el-button type="primary">Click to upload</el-button> -->
                    <el-button class="wenjian"><el-icon>
                            <Plus />
                        </el-icon>选择文件</el-button>
                    <!-- <template #tip>
                        <div class="rwel-upload__tip">
                            jpg/png files with a size less than 500KB.
                        </div>
                    </template> -->
                </el-upload>
            </el-form-item>


        </el-form>
        <template #footer>
            <div class="dialog-footer">
                <el-button type="primary" @click="replyrenwuclick" :loading="button_loading">确定</el-button>
                <el-button @click="replyrenwucancel">
                    取消
                </el-button>
            </div>
        </template>
    </el-dialog>
</template>
<script>
import "../css/renwuitem.css"

export default {
    name: 'RenwuItem',


}
</script>
<script setup>
import { ref, reactive, computed, watch, getCurrentInstance, onMounted, onBeforeUnmount } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import { useStore } from 'vuex'
import {formatDate} from "../config/formatDate.js"
import { getCookie } from '../store/cookieUtil.js';
import handleError from '../config/HandleError.js'
import eventBus from '../js/eventBus.js';
import { getImageSrc, getFileTypeByValue } from '@/config/filetype.js';
import Client from '@/js/Client.js';
import { getFileType } from '@/config/filetype.js';
import filepath from  '@/config/filePath.js';
// import blobToBytes from '../js/blobToBytes.js'
const { proxy } = getCurrentInstance()
const store = useStore()
const client = new Client();
const editrenwu = ref(false)
const activeNames = ref("1");
const emptyimageurl = require('@/img/暂无任务.svg')
// const deleterw = ref(require("../img/deleterw.svg"))
const projectinfo = JSON.parse(sessionStorage.getItem("projectinfo"))
const user = JSON.parse(sessionStorage.getItem('user'))
const xmcyoptions = reactive([]);
const checkAll = ref(false)
const indeterminate = ref(false)
const replycontent = ref(false)

const replyform = reactive(
    {
        describe: "",
        fileList: ""
    }
)
const fileList_reply = ref([])
const button_loading = ref(false)
const getDynamicWidth = (px) => {
    // 根据窗口的宽度动态计算列宽
    const ratio = window.innerWidth / 2560; // 假设 1920 是你设计的基准宽度
    return Math.max(px * ratio, 10) + 'px'; // 保证最小宽度不小于原始值的 80%
}
const handleChange_collapse = (tab) => {
    sessionStorage.setItem('activeCollapseItems', JSON.stringify(tab));
}
onMounted(() => {
    eventBus.on('collapse', CollapseClick);
    eventBus.on('sort', sortlist);
    eventBus.on('sort_reverse', sort_reverse);
})
onBeforeUnmount(() => {
    // 在组件销毁时取消事件监听
    eventBus.off('collapse', CollapseClick);
    eventBus.off('sort', sortlist);
    eventBus.on('sort_reverse', sort_reverse);
});
const CollapseClick = (flag) => {
    //展开
    if (flag) {
        activeNames.value = renwuinfo.arr.map(item => item._id);
    }
    //折叠
    else {
        activeNames.value = []
    }
}
const fileupload = async (event, item) => {
    console.log(activeNames.value)
    filelist.length = 0;
    await handleFileChange(event.file)
    for (const item of item.FileInfos) {
        filelist.push(item.FileId)
    }
    const urldata = new URLSearchParams();
    urldata.append('Token', getCookie('token'));
    urldata.append('Account', user.emailname);
    urldata.append('ProjectId', projectinfo._id);
    urldata.append('ProjectAssignmentId', item._id);
    urldata.append('accountinfos', item.accountinfos);
    urldata.append('Description', item.description);
    urldata.append('deadline', item.deadline);
    urldata.append('FileInfos', filelist);
    const res = await new proxy.$request(proxy.$urls.m().ModifyAssigment, urldata).modepost();
    console.log(res)
    if (!res) return;
    if (res.status != 200) {
        new proxy.$tips(res.data.message, "warning").Mess_age()
        button_loading.value = false;
        return;

    }
    else {
        if (res.data && res.data.Error == 0) {
            eventBus.emit('taskrefresh');
            new proxy.$tips('保存成功', "success").Mess_age()
            editrenwu.value = false;
            fileList_edit.value = []
        }
        else {
            const errorMessage = handleError(res);
            new proxy.$tips(errorMessage, "error").Mess_age()
        }
    }
}
// const restoreActivename=()=>{
//     activeNames.value=JSON.parse(sessionStorage.getItem('activeCollapseItems'))
// }
const previewFile = (file) => {
    console.log(file)
}
const filelist = [];//文件id
const handleFileChange = async (file) => {
    let credentials = null;
    //判断凭证是否过期
    if (client.isCredentialsExpired()) {
        const urldata = new URLSearchParams();
        urldata.append('Account', user.emailname);
        urldata.append('Token', getCookie('token'));
        urldata.append('ProjectId', projectinfo._id);
        const res = await new proxy.$request(proxy.$urls.m().GetSTS, urldata).modepost();
        if (!res) return;
        if (res.status != 200) {
            new proxy.$tips(res.data.message, "warning").Mess_age()
            return;
        }
        else {
            if (res.data && res.data.Error == 0) {
                credentials = res.data;
            }
            else {
                const errorMessage = handleError(res);
                new proxy.$tips(errorMessage, "error").Mess_age()
            }
        }
    }
    const nameWithoutExtension = file.name.replace(/\.\w+$/, "");
    client.file = file;
    client.credentials = credentials
    const timestamp = Date.now(); // 获取当前时间戳
    const extara = projectinfo._id + "/" + 0 + "/" + nameWithoutExtension + "_" + timestamp;
    await client.createClient();
    let ext = file.name.substring(file.name.lastIndexOf('.'));
    const url = filepath + extara + ext;
    let id = null;
    try {
        const file_data = new URLSearchParams();
        file_data.append('Token', getCookie('token'));
        file_data.append('ProjectId', projectinfo._id);
        file_data.append('Account', user.emailname);
        file_data.append('FileName', nameWithoutExtension);
        file_data.append('fileType', getFileType(file.name.split('.').pop().toLowerCase()));
        file_data.append('FolderId', 0);
        file_data.append('FileSize', file.size);
        file_data.append('OSSPath', url);
        let res = null;
        res = await new proxy.$request(proxy.$urls.m().UploadFile, file_data).modepost();
        console.log(res)
        if (!res) return;
        if (res.status != 200) {
            new proxy.$tips(res.data.message, "warning").Mess_age();
        }
        else {
            if (res.data && res.data.Error == 0 && res.data.FileShareInfo) {
                id = res.data.FileShareInfo._id;
                filelist.push(id);
            }
            else if (!res.data && res.data.Error != 0) {
                const errorMessage = handleError(res);
                new proxy.$tips(errorMessage, "error").Mess_age();
            }

        }
    }
    catch (e) {
        new proxy.$tips("上传失败", "error").Mess_age();
    }

};
const finishhandleClick = (row, item) => {
    ElMessageBox.confirm(
        '此操作一经提交将无法恢复，是否继续？',
        '警告',
        {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
        }
    )
        .then(async () => {
            await checkStatus(row, item, 1)

        })
        .catch(() => {
            ElMessage({
                type: 'info',
                message: '已取消删除'
            });
        });
}
const rejectHandleClick = (row, item) => {
    ElMessageBox.confirm(
        '此操作一经提交将无法恢复，是否继续？',
        '警告',
        {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
        }
    )
        .then(async () => {
            await checkStatus(row, item, 2)
        })
        .catch(() => {
            ElMessage({
                type: 'info',
                message: '已取消删除'
            });
        });
}
const checkStatus = async (row, item, SubmissionStatus) => {
    const urldata = new URLSearchParams();
    urldata.append('Token', getCookie('token'));
    urldata.append('Account', row.UserAccount);
    urldata.append('ProjectId', projectinfo._id);
    urldata.append('TaskId', item._id);
    urldata.append('Status', SubmissionStatus);
    urldata.append('ModificationSuggestions', 2);
    urldata.append('SubmissionId', row._id);
    const res = await new proxy.$request(proxy.$urls.m().CheckAssigment, urldata).modepost();
    console.log(res)
    if (!res) return;
    if (res.status != 200) {
        new proxy.$tips(res.data.message, "warning").Mess_age()
        return;
    }
    else {
        if (res.data && res.data.Error == 0) {
            new proxy.$tips('完成', "success").Mess_age()
            eventBus.emit('taskrefresh');
        }
        else {
            const errorMessage = handleError(res);
            new proxy.$tips(errorMessage, "error").Mess_age()
        }
    }
}
const rweditRef = ref(null)
const fileList_edit = ref([])
const rules = reactive({
    describe: [
        { required: true, message: '请输入任务描述', trigger: 'blur' },
    ],
    jzdate: [
        { required: true, message: '请选择截止日期', trigger: 'blur' },
    ],
})
const rwform = reactive(
    {
        describe: "",
        chengyuan: [],
        fileList_load: [],
        jzdate: "",
        creatorName: "",
    }
)
let assignment = null;
const editrenwuplus = (e, item) => {
    e.stopPropagation();
    assignment = item;
    editrenwu.value = true
}
const removeFile = (index) => {
    console.log(index)
    rwform.fileList_load = rwform.fileList_load.filter(file => file.FileId != index);
    console.log(rwform.fileList_load)
}
const deleterenwuplus = (e, id) => {
    e.stopPropagation();
    ElMessageBox.confirm(
        '此操作一经提交将无法恢复，是否继续？',
        '警告',
        {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
        }
    )
        .then(async () => {
            const urldata = new URLSearchParams();
            urldata.append('Token', getCookie('token'));
            urldata.append('Account', user.emailname);
            urldata.append('ProjectId', projectinfo._id);
            urldata.append('TaskId', id);
            const res = await new proxy.$request(proxy.$urls.m().DeleteAssigment, urldata).modepost();
            console.log(res)
            if (!res) return;
            if (res.status != 200) {
                new proxy.$tips(res.data.message, "warning").Mess_age()
                return;
            }
            else {
                if (res.data && res.data.Error == 0) {
                    eventBus.emit('taskrefresh');
                    new proxy.$tips('任务已删除', "success").Mess_age()
                }
                else {
                    const errorMessage = handleError(res);
                    new proxy.$tips(errorMessage, "error").Mess_age()
                }
            }

        })
        .catch(() => {
            ElMessage({
                type: 'info',
                message: '已取消删除'
            });
        });

}
const editrenwuclick = async () => {
    await rweditRef.value?.validate(async (valid) => {
        if (!valid) {
            ElMessage.error("请完善信息，再提交！");
            return false;
        }
        button_loading.value = true;
        filelist.length = 0;
        for (const file of fileList_edit.value) {
            console.log(file)
            await handleFileChange(file.raw)
        }
        console.log(rwform.fileList_load)
        for (const item of rwform.fileList_load) {
            filelist.push(item.FileId)
        }

        const urldata = new URLSearchParams();
        urldata.append('Token', getCookie('token'));
        urldata.append('Account', user.emailname);
        urldata.append('ProjectId', projectinfo._id);
        urldata.append('ProjectAssignmentId', assignment._id);
        urldata.append('accountinfos', rwform.chengyuan);
        urldata.append('Description', rwform.describe);
        urldata.append('deadline', Date.parse(rwform.jzdate));
        urldata.append('FileInfos', filelist);
        const res = await new proxy.$request(proxy.$urls.m().ModifyAssigment, urldata).modepost();
        console.log(res)
        if (!res) return;
        if (res.status != 200) {
            new proxy.$tips(res.data.message, "warning").Mess_age()
            button_loading.value = false;
            return;

        }
        else {
            if (res.data && res.data.Error == 0) {
                eventBus.emit('taskrefresh');
                new proxy.$tips('保存成功', "success").Mess_age()
                editrenwu.value = false;
                fileList_edit.value = []
            }
            else {
                const errorMessage = handleError(res);
                new proxy.$tips(errorMessage, "error").Mess_age()
            }
        }
        button_loading.value = false;

    })

    //发送请求
}
const editrenwucancel = () => {
    editrenwu.value = false;
    rweditRef.value.resetFields()
}
const finishAssignment = (item) => {
    ElMessageBox.confirm(
        '此操作一经提交将无法恢复，是否继续？',
        '警告',
        {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
        }
    )
        .then(async () => {
            console.log(item)
            const urldata = new URLSearchParams();
            urldata.append('Token', getCookie('token'));
            urldata.append('Account', user.emailname);
            urldata.append('ProjectId', projectinfo._id);
            urldata.append('ProjectAssignmentId', item._id);
            const res = await new proxy.$request(proxy.$urls.m().FinishAssigment, urldata).modepost();
            console.log(res)
            if (!res) return;
            if (res.status != 200) {
                new proxy.$tips(res.data.message, "warning").Mess_age()
                return;
            }
            else {
                if (res.data && res.data.Error == 0) {
                    eventBus.emit('taskrefresh');
                    new proxy.$tips('完成', "success").Mess_age()

                }
                else {
                    const errorMessage = handleError(res);
                    new proxy.$tips(errorMessage, "error").Mess_age()
                }
            }

        })
        .catch(() => {
            ElMessage({
                type: 'info',
                message: '已取消删除'
            });
        });
}
const renwuinfo = reactive({
    arr: []
})
const rwlist = computed(() => store.state.rwlist);
const status = computed(() => store.state.status);
const temp_renwulist = reactive({
    arr: []
})
// 使用 watch 来监听 rwlist 和 status
watch([rwlist, status], ([newRwlist, newStatus]) => {
    const temp_status = Number(newStatus);

    renwuinfo.arr = newRwlist.filter(item => {
        // 定义过滤条件
        const shouldInclude = temp_status === -1
            ? item.status === true || item.status === false
            : item.status === Boolean(temp_status);

        // 只保留符合条件的 item
        return shouldInclude;
    }).map(item => {
        // 创建一个新的对象
        const newItem = { ...item }; // 深拷贝 item

        // 如果 Submissioninfo 为空，根据 accountinfos 动态生成数据
        if (newItem.Submissioninfo.length === 0) {
            newItem.Submissioninfo = newItem.accountinfos.map(account => ({
                UserAccount: account,
                Comments: "", // 默认评论为空
                SubmissionTime: "", // 默认提交时间为空
                fileInfos: [] // 默认文件数组为空
            }));
        }

        return newItem; // 返回修改后的 item
    });

    console.log(renwuinfo.arr);
    temp_renwulist.arr = renwuinfo.arr; // 确保通过 .value 访问 ref 的值
    if (renwuinfo.arr.length > 0) {
        activeNames.value = renwuinfo.arr.slice(0, 3).map(item => item._id);
    }
});
const sortlist = (flag) => {
    switch (flag) {
        case '1':
            {
                renwuinfo.arr = temp_renwulist.arr.slice().sort((a, b) => {
                    return new Date(b.createdTime) - new Date(a.createdTime);
                });
                break;
            }
        case '2':
            {
                renwuinfo.arr = temp_renwulist.arr.slice().sort((a, b) => {
                    return new Date(b.deadline) - new Date(a.deadline);
                });
                break;
            }
        case '3':
            {
                renwuinfo.arr = temp_renwulist.arr.slice()
                break;
            }
        case '4':
            {
                renwuinfo.arr = temp_renwulist.arr.slice().sort((a, b) => {
                    return (b.status === false) - (a.status === false)
                });

                break;
            }
        default:
            break;
    }
    if (renwuinfo.arr.length > 0) {
        activeNames.value = renwuinfo.arr.slice(0, 3).map(item => item._id);
    }


}
const sort_reverse = () => {
    renwuinfo.arr.reverse();
    if (renwuinfo.arr.length > 0) {
        activeNames.value = renwuinfo.arr.slice(0, 3).map(item => item._id);
    }
}
const downloadClick = async (item) => {
    new proxy.$tips("下载准备中，请稍等", "warning").Mess_age()
    const chunkSize = 1024 * 1024;
    const totalChunks = Math.ceil(item.FileSize / chunkSize);
    let fileBytes = []; // 用于存储解码的二进制数据
    let flag = true;
    console.log(item)
    for (let chunkIndex = 0; chunkIndex < totalChunks; chunkIndex++) {
        const file_data = new URLSearchParams();
        file_data.append('Token', getCookie('token'));
        file_data.append('ProjectId', projectinfo._id);
        file_data.append('Account', user.emailname);
        file_data.append('FileId', item.FileId);
        // file_data.append('Fileversion', item.FileVersion);
        file_data.append('StartPosition', chunkIndex * chunkSize);
        await new proxy.$request(proxy.$urls.m().DownloadFile, file_data).modepost().then(res => {
            if (res.status != 200) {
                new proxy.$tips(res.data.message, "warning").Mess_age()
                flag = false;
                return;
            }
            else {
                if (res.data.Error == 0 && res && res.data && res.data.FileContent && res.data.FileContent.$binary && res.data.FileContent.$binary.base64) {
                    try {
                        let base64Chunk = res.data.FileContent.$binary.base64;
                        const byteCharacters = atob(base64Chunk);  // Base64 转原始字符串
                        const byteNumbers = new Array(byteCharacters.length);
                        for (let i = 0; i < byteCharacters.length; i++) {
                            byteNumbers[i] = byteCharacters.charCodeAt(i);
                        }
                        const byteArray = new Uint8Array(byteNumbers);

                        // 存储解码后的 Uint8Array 数据块
                        fileBytes.push(byteArray);
                    } catch (decodeError) {
                        new proxy.$tips("Base64 解码失败", "error").Mess_age();
                        flag = false;
                        return;
                    }
                }
                else {
                    const errorMessage = handleError(res);
                    new proxy.$tips(errorMessage, "error").Mess_age();
                    flag = false;
                    return;
                }
            }
        })

    }
    if (flag == false) return;
    try {
        const totalLength = fileBytes.reduce((acc, chunk) => acc + chunk.length, 0); // 计算总长度
        const combinedArray = new Uint8Array(totalLength); // 创建合并后的数组
        let offset = 0;
        fileBytes.forEach(chunk => {
            combinedArray.set(chunk, offset); // 从 offset 开始设置数据
            offset += chunk.length;
        });
        // 将拼接后的块数组转换为 Blob
        const blob = new Blob([combinedArray], { type: 'application/octet-stream' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        console.log(link.href)
        link.download = item.FileName + "." + getFileTypeByValue(item.FileType).toLowerCase();
        document.body.appendChild(link);
        link.click(); // 触发下载
        document.body.removeChild(link);
    }
    catch (error) {
        new proxy.$tips("下载失败", "error").Mess_age()
        console.log(error)
    }


}

const deleteClick = (item, file) => {
    ElMessageBox.confirm(
        '此操作将删除此文件，是否继续？',
        'Warning',
        {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
        }

    )
        .then(async () => {
            filelist.length = 0;
            for (const item of item.FileInfos) {
                if (file.FileId != item.FileId) {
                    filelist.push(item.FileId)
                }
            }
            const urldata = new URLSearchParams();
            urldata.append('Token', getCookie('token'));
            urldata.append('Account', user.emailname);
            urldata.append('ProjectId', projectinfo._id);
            urldata.append('ProjectAssignmentId', item._id);
            urldata.append('accountinfos', item.accountinfos);
            urldata.append('Description', item.description);
            urldata.append('deadline', item.deadline);
            urldata.append('FileInfos', filelist);
            const res = await new proxy.$request(proxy.$urls.m().ModifyAssigment, urldata).modepost();
            console.log(res)
            if (!res) return;
            if (res.status != 200) {
                new proxy.$tips(res.data.message, "warning").Mess_age()
                button_loading.value = false;
                return;

            }
            else {
                if (res.data && res.data.Error == 0) {
                    eventBus.emit('taskrefresh');
                    new proxy.$tips('保存成功', "success").Mess_age()
                    editrenwu.value = false;
                    fileList_edit.value = []
                }
                else {
                    const errorMessage = handleError(res);
                    new proxy.$tips(errorMessage, "error").Mess_age()
                }
            }
        })
}
const loadData = async () => {
    rwform.fileList_load = []
    rwform.describe = assignment.description;
    rwform.chengyuan = assignment.accountinfos;
    rwform.jzdate = formatDate(assignment.deadline);
    console.log(assignment)
    rwform.fileList_load = assignment.FileInfos.map(item => ({
        FileId: item.FileId,
        FileName: item.FileName,
        FileType: item.FileType
    }));
    rwform.creatorName = assignment.creatorName;
    const urldata = new URLSearchParams();
    urldata.append('Account', user.emailname);
    urldata.append('Token', getCookie('token'));
    urldata.append('ProjectId', projectinfo._id);
    const res = await new proxy.$request(proxy.$urls.m().GetUser, urldata).modepost();
    if (!res) return;
    if (res.status != 200) {
        new proxy.$tips(res.data.message, "warning").Mess_age()
        return;
    }
    else {
        if (res.data && res.data.Error == 0) {
            xmcyoptions.length = 0;
            res.data.Permissions.forEach(user => {
                xmcyoptions.push({
                    label: user.AcountName,
                    value: user.AcountName
                });
            });

        }
        else {
            const errorMessage = handleError(res);
            new proxy.$tips(errorMessage, "error").Mess_age()

        }



    }
}
const isUserInAccountInfos = (item) => {

    return item.creatorName == user.emailname && sessionStorage.getItem("flag") != 2;
};
const handleCheckAll = (val) => {
    indeterminate.value = false
    if (val) {
        rwform.chengyuan = xmcyoptions.map((_) => _.value)
        console.log(rwform.chengyuan)
    } else {
        rwform.chengyuan = []
        console.log(rwform.chengyuan)
    }

}
// watchEffect(() => {
//     renwuinfo.arr = rwlist.value.filter(item =>
//         status === -1
//             ? item.FinishState === 0 || item.FinishState === 1
//             : item.FinishState === status
//     );
// });
const replyrenwuclick = async () => {
    filelist.length = 0;
    button_loading.value = true;
    for (const file of fileList_reply.value) {
        console.log(file)
        await handleFileChange(file.raw)
    }
    // console.log(rwform.fileList_load)
    // for (const item of rwform.fileList_load) {
    //     filelist.push(item.FileId)
    // }
    const taskid = sessionStorage.getItem("Taskid")
    const item = JSON.parse(sessionStorage.getItem("item"))
    const urldata = new URLSearchParams();
    urldata.append('Account', item.UserAccount);
    urldata.append('Token', getCookie('token'));
    urldata.append('ProjectId', projectinfo._id);
    urldata.append('TaskId', taskid);
    urldata.append('Comments', replyform.describe);
    urldata.append('FileInfos', filelist);
    const res = await new proxy.$request(proxy.$urls.m().SubmitAssigment, urldata).modepost();
    if (!res) return;
    console.log(res)
    if (res.status != 200) {
        new proxy.$tips(res.data.message, "warning").Mess_age()
        return;
    }
    else {
        if (res.data && res.data.Error == 0) {
            eventBus.emit('taskrefresh');
            new proxy.$tips("回复成功", "success").Mess_age()
        }
        else {
            const errorMessage = handleError(res);
            new proxy.$tips(errorMessage, "error").Mess_age()

        }



    }
    button_loading.value = false;
    replycontent.value = false;
}
const replyrenwucancel = () => {
    replycontent.value = false;
    // replyRef.value.resetFields()
}
const handleClickreply = (row, Taskcode) => {
    //回复
    replycontent.value = true;
    sessionStorage.setItem("Taskid", Taskcode)
    sessionStorage.setItem("item", JSON.stringify(row))
}
</script>