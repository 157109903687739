import * as THREE from 'three';
// import MATERIAL from "../js/material.js";//导入的自定义材料配置
import Selection from './selection.js';//导入某个用于处理选框相关功能的类
import SCHEDULE from "../js/schedule.js";//导入自定义节流函数和调度工具类，能够根据需求调控某些操作的频率，避免频繁渲染。
import picking from "../js/picking.js";//导入用于 3D 场景中的选择和拾取操作，便于用户与场景中的对象进行交互。
export default class Simulation {
	constructor(model, scene, camera, renderer, controls, box_save) {
		this.scene = scene;
		this.capsScene = undefined;//负责渲染经过剪裁或截取部分的区域
		this.backStencil = undefined;//负责渲染模型的背面
		this.frontStencil = undefined;//负责渲染模型的正面
		this.selection = undefined;
		this.model = model;
		this.camera = camera;
		this.renderer = renderer;
		this.controls = controls;
		this.box_save = box_save;
		this.clipbox=null;
		this.clipplaneHelper=null;//辅助剖切工具
		this.init();
		// this.initScene(this.model);
	}

	init() {

		this.capsScene = new THREE.Scene();
		this.backStencil = new THREE.Scene();
		this.frontStencil = new THREE.Scene();
		const box = this.computedBox();
		this.clipbox=box;
		if (this.box_save) {
			this.selection = new Selection(
				this.box_save.limitLow, this.box_save.limitHigh, box
			);
		}
		else {
			this.selection = new Selection(
				box.min, box.max, box
			);
			console.log(box.min, box.max)
		}

		// this.capsScene.add(this.selection.boxMesh);
		// this.scene.add(this.selection.touchMeshes);
		this.scene.add(this.selection.displayMeshes);
		const throttledRender = SCHEDULE.deferringThrottle(this._render.bind(this), this, 40);
		this.throttledRender = throttledRender;

		picking(this); // 必须在 OrbitControls 之前调用，以便可以取消它们
		this.controls.addEventListener('change', throttledRender);//throttledRender 是经过节流处理的版本，意味着即使用户频繁地进行控制操作，渲染函数 _render 也不会被频繁调用，而是在每 40 毫秒内最多调用一次。
		throttledRender();

	}
	computedBox() {
		// 初始化一个全局的包围盒
		const overallBoundingBox = new THREE.Box3();
		const tmpMatrix = new THREE.Matrix4(); // 临时矩阵
		const tmpBox = new THREE.Box3(); // 临时包围盒
		// 遍历所有 InstancedMesh
		this.model.forEach((instancedMesh) => {
			const instanceCount = instancedMesh.count;
			// 对于 InstancedMesh 的每个实例
			for (let i = 0; i < instanceCount; i++) {
				// 获取实例的世界矩阵
				instancedMesh.getMatrixAt(i, tmpMatrix);
				tmpMatrix.multiplyMatrices(instancedMesh.matrixWorld, tmpMatrix);
				// 获取 InstancedMesh 的原始几何体包围盒
				tmpBox.copy(instancedMesh.geometry.boundingBox);
				// 使用实例矩阵变换包围盒（转换到世界坐标系下）
				tmpBox.applyMatrix4(tmpMatrix);
				// 合并到全局包围盒
				overallBoundingBox.expandByPoint(tmpBox.min);
				overallBoundingBox.expandByPoint(tmpBox.max);
			}
		});
		return overallBoundingBox;
	}

	// initScene(collada) {
	// 	const setMaterial = (node, material) => {
	// 		node.material = material;
	// 		if (node.children) {
	// 			for (let i = 0; i < node.children.length; i++) {
	// 				setMaterial(node.children[i], material);
	// 			}
	// 		}
	// 	};

	// 	const back = collada.clone();
	// 	if (MATERIAL && MATERIAL.backStencil) {
	// 		setMaterial(back, MATERIAL.backStencil.clone()); // 设置材质
	// 	} else {
	// 		console.error('MATERIAL.backStencil is undefined');
	// 	}
	// 	// 缩放back对象
	// 	back.scale.set(0.03, 0.03, 0.03);
	// 	back.updateMatrix(); // 更新矩阵
	// 	// 添加back到backStencil
	// 	if (this.backStencil) {
	// 		this.backStencil.add(back); // 添加到父对象
	// 	} else {
	// 		console.error('this.backStencil is undefined');
	// 	}

	// 	const front = collada.clone();
	// 	setMaterial(front, MATERIAL.frontStencil.clone());
	// 	front.scale.set(0.03, 0.03, 0.03);
	// 	front.updateMatrix();
	// 	this.frontStencil.add(front);

	// 	// setMaterial(collada, MATERIAL.sheet);
	// 	// collada.scale.set(0.03, 0.03, 0.03);
	// 	// collada.updateMatrix();
	// 	// this.scene.add(collada);

	// 	this.throttledRender();
	// }

	_render() {
		this.renderer.clear();
		const gl = this.renderer.context;
		if (this.showCaps) {
			this.renderer.state.setStencilTest(true);
			this.renderer.state.setStencilFunc(gl.ALWAYS, 1, 0xff);
			this.renderer.state.setStencilOp(gl.KEEP, gl.KEEP, gl.INCR);
			this.renderer.render(this.backStencil, this.camera);

			this.renderer.state.setStencilFunc(gl.ALWAYS, 1, 0xff);
			this.renderer.state.setStencilOp(gl.KEEP, gl.KEEP, gl.DECR);
			this.renderer.render(this.frontStencil, this.camera);

			this.renderer.state.setStencilFunc(gl.EQUAL, 1, 0xff);
			this.renderer.state.setStencilOp(gl.KEEP, gl.KEEP, gl.KEEP);
			this.renderer.render(this.capsScene, this.camera);
			this.renderer.state.setStencilTest(false);
		}

		this.renderer.render(this.scene, this.camera);
	}
	dispose() {
		const tempbox = {
			limitLow: this.selection.limitLow,
			limitHigh: this.selection.limitHigh,
			clipplaneMap: this.clipplanes
		};
		// 从场景中移除选框相关的物体
		if (this.selection) {
			this.scene.remove(this.selection.touchMeshes);
			this.scene.remove(this.selection.displayMeshes);
		}

		if (this.backStencil) {
			this.scene.remove(this.backStencil);
		}
		if (this.frontStencil) {
			this.scene.remove(this.frontStencil);
		}

		this.capsScene.clear();
		this.backStencil.clear();
		this.frontStencil.clear();
		console.log(this.clipplaneHelper)
		this.scene.remove(this.clipplaneHelper);
		this.renderer.domElement.removeEventListener('mousemove', this.targeting, true);
		this.renderer.domElement.removeEventListener('mousedown', this.beginDrag, false);
		this.renderer.domElement.removeEventListener('touchstart', this.targeting, false);
		this.renderer.domElement.removeEventListener('touchmove', this.beginDrag, false);
		return tempbox;
	}
}
