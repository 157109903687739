import * as THREE from 'three';
import UNIFORMS from '../js/uniforms.js'
import SHADER from '../js/shader.js'
const MATERIAL = {
    sheet: new THREE.ShaderMaterial({
        uniforms: UNIFORMS.clipping,
        vertexShader: SHADER.vertexClipping,
        fragmentShader: SHADER.fragmentClipping
    }),

    cap: new THREE.ShaderMaterial({
        uniforms: UNIFORMS.caps,
        vertexShader: SHADER.vertex,
        fragmentShader: SHADER.fragment
    }),

    backStencil: new THREE.ShaderMaterial({
        uniforms: UNIFORMS.clipping,
        vertexShader: SHADER.vertexClipping,
        fragmentShader: SHADER.fragmentClippingFront,
        colorWrite: false,
        depthWrite: false,
        side: THREE.BackSide
    }),

    frontStencil: new THREE.ShaderMaterial({
        uniforms: UNIFORMS.clipping,
        vertexShader: SHADER.vertexClipping,
        fragmentShader:SHADER.fragmentClippingFront,
        colorWrite: false,
        depthWrite: false
    }),
    temp_BoxBackFace :new THREE.MeshBasicMaterial({ 
        color: 0xff0000, 
        transparent: true, 
        opacity: 1 ,// 设置透明度为 0 
        side: THREE.FrontSide
        
    }),
    BoxBackFace :new THREE.MeshBasicMaterial({ 
        color: 0xdddddd, 
        transparent: true, 
        opacity: 0.2 ,// 设置透明度为 0 
        side: THREE.BackSide,

    }),
    BoxWireframe: new THREE.LineBasicMaterial({ color: 0x000000, linewidth: 0.5,

    }),
    BoxWireActive: new THREE.LineBasicMaterial({ color: 0xe23e31, linewidth: 1 ,

    }),

    Invisible: new THREE.ShaderMaterial({
        vertexShader:SHADER.invisibleVertexShader,
        fragmentShader: SHADER.invisibleFragmentShader,
        polygonOffset:true,
        polygonOffsetFactor:1,
        polygonOffsetUnits :1
    })
};

export default MATERIAL;
