<template>
  <section>
    <div class="head_title">
      <img src="../img/72-2.png" alt="" class="logo">
      <router-link to="/projectList" class="project">项目中心</router-link>
      <router-link to="/datacenter" class="static">数据中心</router-link>
      <!-- <div class="project"><router-link to="/projectitem">项目中心</router-link></div>
      <div class="project"><router-link to="/projectitem">数据中心</router-link></div> -->
      <!-- <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
        <el-tab-pane label="User" name="first">项目中心</el-tab-pane>
    <el-tab-pane label="Config" name="second">数据中心</el-tab-pane>
      </el-tabs> -->
      <el-badge :value=noticeCount class="item" :max="99">
        <img src="../img/notice1.svg" @click="noticeshow" class="noticesvg">
      </el-badge>
      <img :src="convertsrc" class="noticesvg" @click="convert_English" />
      <el-avatar class="head_avatar" @mouseenter="showCard = true"> {{ nickname }} </el-avatar>
      <el-card  v-if="showCard" class="demo-card" shadow="hover" @mouseleave="showCard = false">
        <template #header>
          <div class="card-header">
            <span> {{ nickname }}</span>
          </div>
        </template>
        <div class="mine_card"  @click="mineclick">
          个人中心
        </div>
        <template #footer>
          <div class="logout_card"  @click="logout">
            退出登录
          </div></template>
      </el-card>
      <!-- <el-dropdown>
        <span class="nickname">
          {{ nickname }}
          <el-icon class="el-icon--right">
            <arrow-down />
          </el-icon>
        </span>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item @click="mineclick">个人中心</el-dropdown-item>
            <el-dropdown-item @click="logout">退出登录</el-dropdown-item>

          </el-dropdown-menu>
        </template>
      </el-dropdown> -->
      <!-- <div class="nickname">struggle66</div> -->
    </div>

    <router-view></router-view>
  </section>
</template>

<script>
import '../css/head.css'
import { ref, onMounted, getCurrentInstance, onUnmounted } from 'vue'
import { useRouter } from 'vue-router'
import {  eraseCookie,getCookie } from '../store/cookieUtil.js';
import handleError from '../config/HandleError.js'
import eventBus from '../js/eventBus.js';
export default {
  name: 'HeaderComp',
  props: {
    msg: String
  }
}
</script>
<script setup>
const { proxy } = getCurrentInstance()
const router = useRouter()
const nickname = ref('请登录')
const noticeCount = ref(0)
const showCard = ref(false)
const convertsrc = ref(require('../img/中英文切换-显示中文.svg'))
const noticeshow = () => {
  console.log("njfdnv");
  router.push({
    name: "notice"
  })
}
// const returnproject = () => {
//   router.push({
//     name: "projectList"
//   })
// }
const user = JSON.parse(sessionStorage.getItem("user"))
onMounted(async () => {
  nickname.value = user.emailname;
  eventBus.on('decrement', updateCount);
  const urldata = new URLSearchParams();
  urldata.append('Token', getCookie('token'));
  urldata.append('Account', user.emailname);
  const res = await new proxy.$request(proxy.$urls.m().GetInforms, urldata).modepost();
  console.log(res);
  if (!res) return;
  if (res.status != 200) {
    new proxy.$tips(res.data.message, "warning").Mess_age();
    return;
  }
  if (res.data && res.data.Error === 0) {
    noticeCount.value = res.data.informInfo.filter(item => item.IsRead === false).length;
  } else {
    const errorMessage = handleError(res);
    new proxy.$tips(errorMessage, "error").Mess_age();
  }
  // console.log(nickname)
})
onUnmounted(() => {
  eventBus.off('decrement', updateCount); // 清理事件
});
const logout = () => {
  localStorage.clear();
  sessionStorage.clear();
  eraseCookie('token');
  if ('caches' in window) {
    caches.keys().then(function (cacheNames) {
      cacheNames.forEach(function (cacheName) {
        caches.delete(cacheName);
      });
    });
  }
  router.push({
    name: "login"
  })
}
let Chinese = true
const convert_English = () => {
  if (Chinese) {
    convertsrc.value = require('../img/中英文切换-显示英文.svg')
  } else {
    convertsrc.value = require('../img/中英文切换-显示中文.svg')
  }
  Chinese = !Chinese;

}
const updateCount = () => {
  if (noticeCount.value > 0) {
    noticeCount.value -= 1; // 仅在大于 0 时减少 1
  }
};
const mineclick = () => {
  router.push({
    name: "mine"
  })
}
</script>