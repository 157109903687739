import { createApp } from 'vue'
import App from './App.vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import router from "./router.js"
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import urls from './api/api.js'
import request from './api/request.js'
import createStore from './store/index.js'
import tips from './config/ElMessage.js'
import "amfe-flexible/index.js";
import 'element-plus/theme-chalk/index.css';
import zhCn from 'element-plus/es/locale/lang/zh-cn';
import preview from 'vue3-image-preview';
const app = createApp(App)
app.config.globalProperties.$urls = urls;//请求地址
app.config.globalProperties.$request = request;//请求方法
app.config.globalProperties.$tips = tips;//提示
app.use(router);
app.use(ElementPlus, { locale: zhCn });
app.use(createStore)
app.use(preview);
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
/* 判断当前浏览器是否支持serviceWorker */
// if ('serviceWorker' in navigator) {
//   /* 当页面加载完成就创建一个serviceWorker */
//   window.addEventListener('load', function () {
//       /* 创建并指定对应的执行内容 */
//       /* scope 参数是可选的，可以用来指定你想让 service worker 控制的内容的子目录。 在这个例子里，我们指定了 '/'，表示 根网域下的所有内容。这也是默认值。 */
//       navigator.serviceWorker.register('./serviceWorker.js', {scope: './'})
//           .then(function (registration) {

//               console.log('ServiceWorker registration successful with scope: ', registration.scope);
//           })
//           .catch(function (err) {

//               console.log('ServiceWorker registration failed: ', err);
//           });
//   });
// }

app.mount('#app')

