
import * as THREE from 'three';

const NodeClick = class {
    constructor(scene) {
        this.scene = scene;
        this.InstancedMesh_line = []
    }
    //点击显影按钮
    handleButtonClick = (nodeData) => {
        const { scene } = this;
        nodeData.modelShowflag = !nodeData.modelShowflag;
        // 同步子节点的状态
        this.syncChildrenModelShowFlag(nodeData, nodeData.modelShowflag);
        //循环遍历children获取name
        const names = this.traverseAndCollectNames(nodeData);
        console.log(names);
        names.forEach((name) => {
            for (let j = 0; j < scene.children.length; j++) {
                const child = scene.children[j];
                if (child && child.isInstancedMesh) {
                    const instanceIndex = child.userData.name.findIndex(item =>
                        item.trim().toLowerCase() == name.trim().toLowerCase()
                    );
                    if (instanceIndex !== -1) {
                        const tempMatrix = new THREE.Matrix4();
                        if (nodeData.modelShowflag) {
                            // 显示实例：从 `userData.savedMatrices` 恢复矩阵
                            tempMatrix.copy(child.userData.savedMatrices[instanceIndex]);
                        } else {
                            // 隐藏实例：将实例移到场景外
                            tempMatrix.makeTranslation(10000, 10000, 10000); // 假设场景之外的点
                        }
                        // 更新目标实例的矩阵
                        child.setMatrixAt(instanceIndex, tempMatrix);
                        child.instanceMatrix.needsUpdate = true; // 通知渲染器更新
                    }
                }
            }


        });
    }
    //同步图标
    syncChildrenModelShowFlag = (node, newStatus) => {
        if (node.children && node.children.length > 0) {
            node.children.forEach(child => {
                child.modelShowflag = newStatus; // 同步子节点的值
                this.syncChildrenModelShowFlag(child, newStatus); // 递归调用子节点
            });
        }
    }
    computeCentroid(points) {
        const centroid = new THREE.Vector3(0, 0, 0);
        // 遍历所有点，将它们相加
        points.forEach(point => {
            centroid.add(point);
        });
        // 除以点的数量，计算平均值
        centroid.divideScalar(points.length);
        return centroid;  // 返回质心
    }

    traverseAndCollectNames(node) {
        // 保存所有 name 的数组
        let names = [];
        // 获取当前节点的 name
        if (node.name) {
            names.push(node.name);
        }
        // 如果这个节点有 children，遍历每一个孩子节点
        if (node.children && Array.isArray(node.children)) {
            for (let child of node.children) {
                // 递归处理孩子节点，并将结果合并到 names 数组中
                names = names.concat(this.traverseAndCollectNames(child));
            }
        }
        return names;
    }
    traverseAndCollectMaterials(node, flag) {
        if (node.material) {
            node.material.wireframe = flag
        }
        // 如果这个节点有 children，遍历每一个孩子节点
        if (node.children && Array.isArray(node.children)) {
            for (let child of node.children) {
                this.traverseAndCollectMaterials(child, flag)
            }
        }
    }
    traverseAndCollectLeafNodesAndMaterials(node) {
        // 保存所有叶子节点的数组
        let leafNodesArray = [];
        // 保存所有叶子节点的材质数组
        let materialArray = [];

        // 递归处理每个节点
        const traverse = (node) => {
            // 如果节点有子节点，递归处理子节点
            if (node.children && node.children.length > 0) {
                // 遍历每个子节点
                for (let child of node.children) {
                    traverse(child);
                }
            } else {
                // 如果没有子节点（即叶子节点），保存节点和材质
                leafNodesArray.push(node);

                // 如果节点有材质属性，保存该节点的材质
                if (node.material) {
                    if (Array.isArray(node.material)) {
                        // 如果材质是数组，展开并加入 materialArray
                        materialArray.push(...node.material);
                    } else {
                        // 单一材质
                        materialArray.push(node.material);
                    }
                }
            }
        };

        // 从根节点开始递归
        traverse(node);

        // 返回两个数组：叶子节点和它们的材质
        return {
            leafNodes: leafNodesArray,
            materials: materialArray
        };
    }

    //存贴图
    traverseMaterialsCollectTexture(node) {
        // 保存所有叶子节点的数组
        let TextureMeshArray = [];
        // 保存所有叶子节点的材质数组
        let materialArray = [];
        const traverse = (node) => {
            if (node.children && node.children.length > 0) {
                // 遍历每个子节点
                for (let child of node.children) {
                    traverse(child);
                }
            } else {
                // 如果节点有贴图
                if (node.material && node.material.map && node.material.map.isTexture) {
                    TextureMeshArray.push(node);
                    materialArray.push(node.material);
                    const m = node.material.clone();
                    m.map = null;
                    m.color.setHex(0x55564d)
                    node.material = m;
                }
            }
        };

        // 从根节点开始递归
        traverse(node);

        // 返回两个数组：叶子节点和它们的材质
        return {
            TextureNodes: TextureMeshArray,
            materials: materialArray
        };
    }
    getBoundingSphereCenterInWorld(mesh, instanceId) {
        const geometry = mesh.geometry;
        if (!geometry || !geometry.attributes.position) {
            console.warn('该网格没有适当的几何体数据');
            return null;
        }

        geometry.computeBoundingSphere();
        const boundingCenterLocal = geometry.boundingSphere.center.clone();

        // 获取实例的变换矩阵
        const matrix = new THREE.Matrix4();
        mesh.getMatrixAt(instanceId, matrix);

        // 将局部坐标转换为世界坐标
        const boundingCenterWorld = boundingCenterLocal.applyMatrix4(matrix).applyMatrix4(mesh.matrixWorld);
        return boundingCenterWorld;
    }

    traverseallMesh(node) {
        const lines = []
        const traverse = (node) => {
            if (node.children && node.children.length > 0) {
                // 遍历每个子节点
                for (let child of node.children) {
                    traverse(child);
                }
            } else if (node.isInstancedMesh) {
                // 遍历每个实例的变换矩阵
                const instanceCount = node.count;
                const edgesList = []; // 保存每个 InstancedMesh 的线框
                for (let i = 0; i < instanceCount; i++) {
                    const instanceMatrix = new THREE.Matrix4();
                    node.getMatrixAt(i, instanceMatrix);

                    // 创建边缘线框
                    const edges = new THREE.EdgesGeometry(node.geometry, 10);
                    const edgesMaterial = new THREE.LineBasicMaterial({ color: 0xfac019 });
                    const line = new THREE.LineSegments(edges, edgesMaterial);

                    // 应用实例的变换矩阵
                    line.applyMatrix4(instanceMatrix);
                    lines.push(line)
                    edgesList.push(line);
                    // 将生成的线框添加到场景中
                    this.scene.add(line);
                }
                this.InstancedMesh_line[node.uuid] = edgesList;
            }

        };
        for (const item of node) {
            traverse(item); // 对每个数组项递归处理
        }
        return lines;
    }
    findParameters(object) {
        // 如果对象有userData，并且userData中存在Parameters，则返回该值
        if (object && object.userData && 'Parameters' in object.userData) {
            return object;
        }
        // 如果没有父对象，则说明已经到最顶级对象，没有找到Parameters
        if (!object.parent) {
            return null;  // 未找到Parameters，返回null或者undefined
        }
        // 递归向上查找父级对象的Parameters，直到最顶级或找到为止
        return this.findParameters(object.parent);
    }
    shadowMap(node) {
        const traverse = (node) => {
            if (node.children && node.children.length > 0) {
                // 遍历每个子节点
                for (let child of node.children) {
                    traverse(child);
                }
            } else if (node.isMesh) {
                if (node.material instanceof THREE.MeshStandardMaterial || node.material instanceof THREE.MeshPhysicalMaterial) {
                    // 设置环境映射
                    node.material.envMapIntensity = 1;
                    // 启用阴影
                    node.castShadow = true;  // 投射阴影
                    node.receiveShadow = true; // 接收阴影
                    // 如果需要，还可以调整物理材质的其他参数
                    // node.material.roughness = 15; // 控制粗糙度
                    // node.material.metalness = 15; // 控制金属度
                }
            }

        };
        traverse(node);

    }
    traverseAndCollectChildren(node) {
        let ids = [];
        // 获取当前节点的 name
        if (node.value) {
            ids.push(node.value);
        }
        // 如果这个节点有 children，遍历每一个孩子节点
        if (node.children && Array.isArray(node.children)) {
            for (let child of node.children) {
                // 递归处理孩子节点，并将结果合并到 names 数组中
                ids = ids.concat(this.traverseAndCollectChildren(child));
            }
        }
        return ids;
    }
    testInstanceMesh(count = 10000) {
        const geometry = new THREE.IcosahedronGeometry(3, 3);
        const material = new THREE.MeshPhongMaterial({ flatShading: true }); // 使用平面着色优化性能
        geometry.computeVertexNormals();

        const instancedMesh = new THREE.InstancedMesh(geometry, material, count);

        const matrix = new THREE.Matrix4(); // 矩阵用于变换实例
        const color = new THREE.Color();
        const x = 1000;
        const y = 1000;
        const z = 1000;

        for (let i = 0; i < count; i++) {
            // 生成随机位置和颜色
            matrix.setPosition(
                Math.random() * x - x / 2, // 中心为 0
                Math.random() * y - y / 2,
                Math.random() * z - z / 2
            );
            instancedMesh.setMatrixAt(i, matrix);

            // 为实例设置随机颜色
            color.setHex(Math.random() * 0xffffff);
            instancedMesh.setColorAt(i, color);
        }
        instancedMesh.instanceMatrix.needsUpdate = true; // 更新实例矩阵
        instancedMesh.instanceColor.needsUpdate = true; // 更新实例颜色

        instancedMesh.castShadow = true; // 启用投影阴影
        instancedMesh.receiveShadow = true; // 启用接收阴影

        this.scene.add(instancedMesh); // 将实例化网格添加到场景

        return instancedMesh;
    }
    findNodeByName = (nodes, targetName) => {
        for (const node of nodes) {
            if (node.name&&node.name === targetName) {
                return node; // 找到匹配的节点
            }
            if (node.children) {
                const found = this.findNodeByName(node.children, targetName);
                if (found) {
                    return found; // 在子节点中找到
                }
            }
        }
        return null; // 如果没有找到
    };
    

}
export default NodeClick