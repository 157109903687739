
import router from "@/router"
const FileType = {
    glb: 0,
    rvt: 1,
    pdf: 2,
    ppt: 3,
    docx: 4,
    xlsx: 5,
    dwg: 6,
    jpg: 7,
    jpeg: 8,
    png: 9,
    avi: 10,
    mp4: 11,
    gltf: 12,
    bin: 13,
    dwf: 14,
    tiff: 15,
    bmp: 16,
    pptx: 17,
    other: 18
};
const FileType_show = {

    rvt: 1,
    pdf: 2,
    ppt: 3,
    docx: 4,
    xlsx: 5,
    dwg: 6,
    jpg: 7,
    jpeg: 8,
    png: 9,
    avi: 10,
    mp4: 11,
    dwf: 14,
    tiff: 15,
    bmp: 16,
    pptx: 17,
    other: 18
};


// 定义获取文件类型的方法
function getFileType(type) {
    switch (type) {
        case 'glb':
            return FileType.glb;
        case 'rvt':
            return FileType.rvt;
        case 'pdf':
            return FileType.pdf;
        case 'ppt':
            return FileType.ppt;
        case 'pptx':
            return FileType.pptx;
        case 'docx':
            return FileType.docx;
        case 'xlsx':
            return FileType.xlsx;
        case 'dwg':
            return FileType.dwg;
        case 'jpg':
            return FileType.jpg;
        case 'jpeg':
            return FileType.jpeg;
        case 'png':
            return FileType.png;
        case 'avi':
            return FileType.avi;
        case 'mp4':
            return FileType.mp4;
        case 'gltf':
            return FileType.gltf;
        case 'bin':
            return FileType.bin;
        case 'dwf':
            return FileType.dwf;
        case 'tiff':
            return FileType.tiff;
        case 'bmp':
            return FileType.bmp;
        default:
            return FileType.other;
    }
}
// 定义根据值获取文件类型的方法
function getFileTypeByValue(value) {
    for (const [key, val] of Object.entries(FileType)) {
        if (val === value) {
            return key; // 返回文件类型字符串
        }
    }
    return 'unknown'; // 如果找不到，返回 unknown
}
function getImageSrc(fileType) {
    switch (fileType) {
        case 0:
            return require('@/img/glb.svg'); // PDF 图标的路径
        case 1:
            return require('@/img/RVT.svg'); // Word 文档图标的路径
        case 2:
            return require('@/img/pdf.svg'); // 默认图标路径
        case 3:
            return require('@/img/ppt.svg'); // PowerPoint 图标的路径
        case 4:
            return require('@/img/word.svg');
        case 5:
            return require('@/img/excel.svg');
        case 6:
            return require('@/img/dwg.svg');
        case 7:
            return require('@/img/JPG图标.svg');
        case 8:
            return require('@/img/JPEG.svg');
        case 9:
            return require('@/img/png.svg');
        case 10:
            return require('@/img/avi.svg');
        case 11:
            return require('@/img/mp4.svg');
        case 12:
            return require('@/img/glTF.svg');
        case 13:
            return require('@/img/二进制.svg');
        case 14:
            return require('@/img/DWF.svg');
        case 15:
            return require('@/img/TIFF.svg');
        case 16:
            return require('@/img/BMP图标.svg');
        case 17:
            return require('@/img/ppt.svg');
        // 其他文件类型的处理
        default:
            return require('@/img/pdf.svg'); // 默认图标路径
    }
}
function navigateBasedOnFileType(e) {
    sessionStorage.setItem('fileinfo', JSON.stringify(e));
    switch (e.FileType) {
        case 0: {
            router.push({ name: 'bimviewer' });
            break; // 可选：使用 break 结束 case
        }
        case 1: {
            router.push({ name: 'bimviewer' });
            break;
        }
        case 2: {
            router.push({ name: 'pdfviewer' });
            break;
        }
        case 3:
        case 17: {
            router.push({ name: 'pptxviewer' });
            break;
        }
        case 4: {
            router.push({ name: 'docxviewer' });
            break;
        }
        case 5: {
            router.push({ name: 'excelviewer' });
            break;
        }
        default: {
            console.warn('未知文件类型:', e.FileType);
            break;
        }
    }
}
export { getFileType, getFileTypeByValue, getImageSrc, navigateBasedOnFileType, FileType, FileType_show };