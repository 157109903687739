<template>
  <div class="out">
    <Headercom />
    <div class="pro_inner">
      <el-tabs v-model="activeName" type="card" class="pro_demo-tabs" @tab-change="handleClick">
        <el-tab-pane :label="`全部 ${TotalCount}`" name="Total" :style="paneStyle('Total')">
          <Projectitem ref="typevalueplus"></Projectitem>
        </el-tab-pane>
        <el-tab-pane :label="`进行中 ${unfinishedCount}`" name="first" :style="paneStyle('first')">
          <Projectitem ref="typevalueplus"></Projectitem>
        </el-tab-pane>
        <el-tab-pane :label="`已完成 ${finishedCount}`" name="second" :style="paneStyle('second')">
          <Projectitem ref="typevalueplus"></Projectitem>
        </el-tab-pane>
      </el-tabs>
      <div class="pro_type">类型</div>
      <el-select v-model="typevalue" placeholder="请选择" size="primary" filterable @change="typeselect"
        class="type_select">
        <el-option v-for="item in typeoptions" :key="item.value" :label="item.label" :value="item.value" />
      </el-select>
      <el-input v-model="searchitem" class="search-input" placeholder="搜索项目">
        <template #append>
          <el-button type="primary" @click="searchclick">
            <el-icon>
              <Search />
            </el-icon>
          </el-button>
        </template>
      </el-input>
      <el-button color="#4976f3" :dark="isDark" @click="xmdialogshow" class="create-btn">新建项目</el-button>

    </div>
    <!-- <router-view></router-view> -->
    <el-dialog v-model="dialogFormVisible" title="新建项目" width="fit-content" center :close-on-click-modal="false">
      <el-form ref="xmcreateRef" :model="ruleForm" :rules="rules" label-width="auto" class="demo-ruleForm"
        :size="formSize" status-icon>
        <el-form-item label="项目类型" required>
          <el-select v-model="ruleForm.type" placeholder="请选择一个类型">
            <el-option label="公建" value="0" />
            <el-option label="住宅" value="1" />
            <el-option label="市政" value="2" />
            <el-option label="水利" value="3" />
            <el-option label="其他" value="4" />
          </el-select>
        </el-form-item>
        <el-form-item label="客户编号">
          <el-input v-model="ruleForm.khbh" type="number" />
        </el-form-item>
        <el-form-item label="项目名称" required prop="xmmc">
          <el-input v-model="ruleForm.xmmc" />
        </el-form-item>
        <el-form-item label="项目面积" required prop="mianji">
          <el-input v-model="ruleForm.mianji" autocomplete="off"><template #append>m²</template></el-input>
        </el-form-item>
        <el-row>
          <el-col :span="24">
            <el-form-item label="项目地址" required prop="xmdz">
              <el-cascader size="middle" :options="pcaTextArr" v-model="selectedOptions" @change="handlepositionChange">
              </el-cascader>
              <el-input v-model="ruleForm.xmdz" placeholder="请输入详细地址" style="margin-top:10px;" />
            </el-form-item>
          </el-col>
        </el-row>

        <el-form-item label="建设单位" required prop="jsdw">
          <el-input v-model="ruleForm.jsdw" />
        </el-form-item>
        <el-form-item label="设计单位" required prop="sjdw">
          <el-input v-model="ruleForm.sjdw" />
        </el-form-item>
        <el-form-item label="监理单位" required prop="jldw">
          <el-input v-model="ruleForm.jldw" />
        </el-form-item>
        <el-form-item label="施工单位" required prop="sgdw">
          <el-input v-model="ruleForm.sgdw" />
        </el-form-item>
        <el-row>
          <el-col :span="12">
            <el-form-item label="开工日期" required prop="kgdate">
              <el-date-picker v-model="ruleForm.kgdate" type="date" placeholder="请选择" style="width: 100%" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="竣工日期" required prop="jgdate">
              <el-date-picker v-model="ruleForm.jgdate" type="date" placeholder="请选择" style="width: 100%" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="项目封面" required prop="xmimage">
          <el-upload class="pro_avatar-uploader" action="#" :show-file-list="false" :limit="1" :auto-upload="false"
            accept=".jpg,.jpeg,.png" @change="handleChange_avatar">
            <img v-if="ruleForm.xmimage" :src="ruleForm.xmimage" class="pro_avatar"
              :preview-src-list="[ruleForm.xmimage]" />
            <el-icon class="pro_avatar-uploader-icon" v-else>
              <Plus />
            </el-icon>
          </el-upload>
        </el-form-item>
        <el-form-item label="备注">
          <el-input v-model="ruleForm.xmbeizhu" />
        </el-form-item>
        <div class="care">请注意：所有信息一经填写将不可修改</div>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button type="primary" @click="createxm" :loading="button_loading">
            创建
          </el-button>
          <el-button @click="createxmcancel">取消</el-button>

        </div>
      </template>
    </el-dialog>

  </div>
</template>
<!-- <script setup>
import { Search } from '@element-plus/icons-vue'

</script> -->
<script>
import Headercom from './head.vue'
import Projectitem from './projectitem.vue'
import { pcaTextArr } from 'element-china-area-data'
import { ref, reactive, onMounted, getCurrentInstance, onUnmounted } from 'vue'
import { getCookie } from '../store/cookieUtil.js';
import handleError from '../config/HandleError.js'
import { ElMessage,ElMessageBox } from 'element-plus'
import '../css/home.css'
import { useStore } from 'vuex'
import blobToBytes from '../js/blobToBytes.js'
import eventBus from '@/js/eventBus';
// import blobToBytes from '../js/blobToBytes.js'
export default {
  name: 'HomeComp',
  props: {
    msg: String,
  },
  components: {
    Headercom,
    Projectitem
  },
  setup() {

  },
  data() {
    return {
      pcaTextArr,
    }
  },
}
</script>
<script setup>
const { proxy } = getCurrentInstance()
const store = useStore()
const xmcreateRef = ref(null);
const activeColor = ref('#ffffff');
const searchitem = ref('')
const activeName = ref('first');
const dialogFormVisible = ref(false);
const typevalue = ref(-1);
const typevalueplus = ref('')
const unfinishedCount = ref(0);
const finishedCount = ref(0)
const TotalCount= ref(0);
const button_loading = ref(false);
const typeoptions = reactive(
  [
    {
      value: -1,
      label: '全部',
    },
    {
      value: 0,
      label: '公建',
    },
    {
      value: 1,
      label: '住宅',
    },
    {
      value: 2,
      label: '市政',
    },
    {
      value: 3,
      label: '水利',
    },
    {
      value: 4,
      label: '其他',
    },

  ]
);
const ruleForm = reactive(
  {
    type: "0",
    mianji: "",
    khbh: "",
    xmmc: "",
    xmdz: "",
    jsdw: "",
    sjdw: "",
    jldw: "",
    sgdw: "",
    kgdate: "",
    jgdate: "",
    xmimage: "",
    xmbeizhu: ""
  }
);
const rules = reactive(
  {

    mianji: [
      { required: true, message: '请输入项目面积', trigger: 'blur' },

    ],
    // khbh: [
    //   { required: true, message: '请输入客户编号', trigger: 'blur' },

    // ],
    xmmc: [
      { required: true, message: '请输入项目名称', trigger: 'blur' }
    ],
    xmdz: [
      { required: true, message: '请输入详细地址', trigger: 'blur' }
    ],
    jsdw: [
      { required: true, message: '请输入建设单位', trigger: 'blur' }
    ],
    sjdw: [
      { required: true, message: '请输入设计单位', trigger: 'blur' }
    ],
    jldw: [
      { required: true, message: '请输入监理单位', trigger: 'blur' }
    ],
    sgdw: [
      { required: true, message: '请输入施工单位', trigger: 'blur' }
    ],
    kgdate: [
      { required: true, message: '请选择开工日期', trigger: 'blur' }
    ],
    jgdate: [
      { required: true, message: '请选择竣工日期', trigger: 'blur' },
      { validator: validateJgdate, trigger: 'change' }
    ],
    xmimage: [
      { required: true, message: '请选择项目封面图片', trigger: 'blur' }
    ]
  }
);
let selectedOptions = [];
// const imageUrl = "";
const user = JSON.parse(sessionStorage.getItem('user'))
const paneStyle = (paneName) => {
  return {
    'background-color': activeName.value === paneName ? activeColor.value : ''
  };
};
let temp_prolist = null;
const handleClick = (tab) => {
  // store.dispatch('prolisttypefun', tab);//放到store中

  if (tab == "first") {
    temp_prolist = projectlistTotal.filter(item => item.FinishState === 0);
  }
  else if (tab == "second") {
    temp_prolist = projectlistTotal.filter(item => item.FinishState === 1);
  }
  else{
    temp_prolist = projectlistTotal;
  }
  typevalue.value = -1
  store.dispatch('projectlistfun', temp_prolist)
};
onMounted(async () => {
  //获取项目列表
  await fetchProjectData();
  eventBus.on('projectrefresh', fetchProjectData);
})
onUnmounted(() => {
  eventBus.off('projectrefresh', fetchProjectData);
})
const typeselect = (e) => {
  //向子组件传值
  let list = null;
  if (e === -1) {
    list = temp_prolist;
  }
  else {
    list = temp_prolist.filter(item =>
      item.CivilType === e);
  }
  store.dispatch('projectlistfun', list)
}
const searchclick = () => {
  store.dispatch('searchclickfun', searchitem)
}
const xmdialogshow = () => {
  dialogFormVisible.value = true;
}
function validateJgdate(rule, value, callback) {
  const kgdate = ruleForm.kgdate;
  if (kgdate && value) {
    if (new Date(value) < new Date(kgdate)) {
      callback(new Error('竣工日期必须晚于开工日期'));
    } else {
      callback(); // 校验通过
    }
  } else {
    callback(); // 如果没有选择日期，跳过校验
  }
}
const createxm = () => {
  xmcreateRef.value.validate((valid) => {
    if (!valid) {
      ElMessage.error("请完善表单信息，再提交！");
      return false;
    }
    ElMessageBox.confirm(
      '请确认所填写信息无误，一旦创建后将无法修改！',
      '警告',
      {
        confirmButtonText: '确认无误',
        cancelButtonText: '取消',
        type: 'warning',
        draggable: true,
      }
    )
      .then(async() => {
        button_loading.value = true;
        try {
          // const byteArray = await blobToBytes(file);
          // 表单验证通过，执行提交逻辑
          const byteArray = await blobToBytes(imageFile);
          const file_data = new URLSearchParams();
          file_data.append('Token', getCookie('token'));
          file_data.append('CivilType', ruleForm.type);
          file_data.append('CivilSquare', ruleForm.mianji);
          file_data.append('CivilInternalNumber', ruleForm.khbh);
          file_data.append('CivilName', ruleForm.xmmc);
          file_data.append('CivilArea', (selectedOptions.join('')).concat(ruleForm.xmdz));
          file_data.append('ConstructionCompany', ruleForm.jsdw);
          file_data.append('DesignCompany', ruleForm.sjdw);
          file_data.append('SupervisionCompany', ruleForm.jldw);
          file_data.append('ConstructionContractor', ruleForm.sgdw);
          file_data.append('Account', user.emailname);
          file_data.append('Description', ruleForm.xmbeizhu);
          file_data.append('CommenceDate', Date.parse(ruleForm.kgdate));
          file_data.append('CompletionDate', Date.parse(ruleForm.jgdate));
          file_data.append('coverData', byteArray);
          file_data.append('ImageType', imageFile.name.split('.').pop());
          const res = await new proxy.$request(proxy.$urls.m().CreateBIM, file_data).modepost();
          if (!res) return;
          if (res.status != 200) {
            new proxy.$tips(res.data.message, "warning").Mess_age()
            return;
          }
          else {
            if (res.data && res.data.Error == 0) {
              console.log(res)
              new proxy.$tips("创建成功", "success").Mess_age()
              dialogFormVisible.value = false;
              await fetchProjectData();
              dialogFormVisible.value = false;
            }
            else {
              const errorMessage = handleError(res);
              new proxy.$tips(errorMessage, "error").Mess_age()

            }
          }

        }
        catch (err) {
          console.log(err)
        }
        finally {
          button_loading.value = false;
        }
      })



  });
}
const createxmcancel = () => {
  dialogFormVisible.value = false;
  xmcreateRef.value.resetFields()
}
const handlepositionChange = (value) => {
  selectedOptions = value; // 选中的值
}
let imageFile = null;
const handleChange_avatar = (e) => {
  console.log(e)
  ruleForm.xmimage = URL.createObjectURL(e.raw)
  imageFile = e.raw;
}
let projectlistTotal = null;
// 获取项目列表的函数
const fetchProjectData = async () => {
  const urldata = new URLSearchParams();
  urldata.append('Token', getCookie('token'));
  urldata.append('Account', user.emailname);
  const res = await new proxy.$request(proxy.$urls.m().GetBIM, urldata).modepost();
  console.log(res);
  if (!res) return;
  if (res.status != 200) {
    new proxy.$tips(res.data.message, "warning").Mess_age();
    return;
  }
  if (res.data && res.data.Error === 0) {
    projectlistTotal = res.data.BIMARIntegrationInfosProto;
    // projectdata.splice(0, projectdata.length, ...res.data.BIMARIntegrationInfosProto);
    unfinishedCount.value = projectlistTotal.filter(item => item.FinishState === 0).length;
    finishedCount.value = projectlistTotal.length - unfinishedCount.value;
    TotalCount.value= projectlistTotal.length;
    if (activeName.value == "first") {
      temp_prolist = projectlistTotal.filter(item => item.FinishState === 0);
    }
    else {
      temp_prolist = projectlistTotal.filter(item => item.FinishState === 1);
    }
    store.dispatch('projectlistfun', temp_prolist)
  } else {
    const errorMessage = handleError(res);
    new proxy.$tips(errorMessage, "error").Mess_age();
  }
};

</script>