<template>
    <section>
    <div class="title">
      <img src="../img/72-2.png" alt="">
      <a @click="loginplus" class="linklogin">立即登录</a>
    </div>
    <form action="" class="login-view">
      <div class="re_inner">
        <div class="image"><img src="../img/registersuccess.svg"></div>
        <div class="succ">
          <div class="text">恭喜你注册成功！</div>
        </div>
        <div class="zhanghao">
          登录账号:{{ confirm_email }}
        </div>
        <input type="button" value="立即登录" @click="loginplus"><br>
      </div>
    </form>
  </section>
</template>
<script>
import '../css/registerthree.css'
import { ref} from 'vue'
import { useRouter } from 'vue-router'
import {useStore} from 'vuex'
export default {
    name: 'HelloWorld',
    props: {
      msg: String
    },
    setup(){
    const router = useRouter();
    const loginplus=()=>{
        router.push({
              name:'login'
            })
      }
      const store=useStore();
      const confirm_email=ref(store.state.emailname)
    return {loginplus,confirm_email};
  }
  }
  </script>
  <style scoped>

</style>