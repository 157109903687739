<template>
  <div class="background">
    <section>
      <div class="logo-company">
        <img src="../img/bim2.png" alt="">
      </div>

      <div id="login-view">
        <div class="login_innerplus">
          <div class="login_add">
            <div class="denglu">登录</div>
            <div class="denglu-plus">Login</div>
          </div>
          <div class="login_inner">
            <!-- <el-autocomplete v-model="state1" :fetch-suggestions="querySearch" clearable class="inline-input w-50"
              placeholder="Please Input" @select="handleSelect" /> -->
            <el-input v-model="user.emailname" type="text" placeholder=" 请输入手机号" class="login_input1" />
            <div class="line"></div>
            <el-input v-model="user.password" type="password" placeholder="请输入密码" show-password class="login_input2" />
            <div class="line"></div>
            <el-checkbox v-model="checked" label="记住密码" size="small" class="login_checkbox" />
            <el-checkbox v-model="checked_xieyi" label="记住密码" size="small" class="agree">
              <label for="agree" class="a">我同意
                <a class="xieyi" href="/serviceAgreement.htm" target="_blank">《彼凡服务协议》</a>和<a class="xieyi"
                  href="/privacyAgrement.htm" target="_blank">《彼凡隐私协议》</a></label>
            </el-checkbox>
            <div>
              <el-button type="primary" @click="login" class="login_submit" :loading="loading">开始使用</el-button>
            </div>

            <router-link to="/forget" class="forget-password">&ensp;忘记密码</router-link>
          </div>

        </div>
        <div class="register">
          <router-link to="/register" class="registerplus">立即注册</router-link>
        </div>
      </div>

    </section>
    <footer>
      <div class="about">
        <div class="xieyi">
          <a href="##">关于彼凡</a> | <a href="##">联系我们</a> | <a href="/serviceAgreement.htm" target="_blank">服务协议</a> | <a
            href="/privacyAgrement.htm" target="_blank">隐私协议</a>
        </div>
        <div class="login_right">
          <p>Copyright © 2022-2025 Bfine-Tech. All Rights Reserved. &nbsp;&nbsp;&nbsp; <a
              href="https://beian.miit.gov.cn/" target="_blank">浙ICP备2024127068号-1</a> </p>
        </div>

      </div>
    </footer>
  </div>
</template>
<script>
import "../css/login.css"
import { ref, reactive, getCurrentInstance, onMounted, } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import handleError from '../config/HandleError.js'
import { setCookie } from '../store/cookieUtil.js';
export default {
  name: 'LoginA',
  props: {
    msg: String
  },


}
</script>
<script setup>
const { proxy } = getCurrentInstance()
const router = useRouter();
const store = useStore();
const checked = ref(false);
const checked_xieyi = ref(false);
const loading = ref(false)
const user = reactive({
  emailname: "",
  password: ""
})
const login = async () => {
  if (user.emailname == "" || user.password == "") {
    alert("手机号和密码不能为空");
    return false;
  }
  else if (user.password.length < 6 || user.password.length > 30) {
    alert("密码的位数必须在6~30之间")
    return false;
  }
  else if (checked_xieyi.value == false) {
    alert("请阅读并勾选彼凡服务协议和彼凡隐私协议");
    return false;
  }
  loading.value = true;
  try {
    const urldata = new URLSearchParams();
    urldata.append('account', user.emailname);
    urldata.append('password', user.password);
    const res = await new proxy.$request(proxy.$urls.m().login, urldata).modepost();
    console.log(res)
    if (!res) return;
    if (res.status != 200) {
      new proxy.$tips(res.data.message, "warning").Mess_age()
      return;
    }
    else {
      if (res.data && res.data.Error == 0) {
        //登录成功
        new proxy.$tips("登录成功", "success").Mess_age()
        store.dispatch('emailfun', user.emailname)
        router.replace({
          name: 'head'
        })//禁止跳转到登录页面
        sessionStorage.setItem('user', JSON.stringify(user))
        if (checked.value == true) {
          //记住密码
          localStorage.setItem('user', JSON.stringify(user))
        }
        //保存token
        setCookie('token', res.data.Token, 7);
      }
      else {
        const errorMessage = handleError(res);
        new proxy.$tips(errorMessage, "error").Mess_age()

      }



    }
  }
  catch (e) {
    new proxy.$tips(e.message, "error").Mess_age()
  }
  finally {
    loading.value = false
  }
}

onMounted(() => {
  if (localStorage.getItem('user')) {
    // 直接更新响应式对象
    const olduser = JSON.parse(localStorage.getItem('user'))
    user.emailname = olduser.emailname;
    user.password = olduser.password;
    checked.value = true;
  }
})</script>
