
import OSS from 'ali-oss'
import eventBus from './eventBus';
class ClientSTS {
    constructor() {
        this.credentials = null;
        this.file = null;
        this.extara = null;
    }
    createClient = async () => {
        const client = new OSS({
            bucket: "bfine-official-website",
            region: "oss-cn-hangzhou",
            accessKeyId: this.credentials.AccessKeyId,
            accessKeySecret: this.credentials.AccessKeySecret,
            stsToken: this.credentials.SecurityToken,
        });
        // 分离文件名和扩展名
        let ext = this.file.name.substring(this.file.name.lastIndexOf('.'));
        // 执行上传，并传入 onProgress 选项
        await client.multipartUpload(this.extara + ext, this.file, {
            progress: this.onProgress
        });

    }
    isCredentialsExpired() {
        if (!this.credentials) {
            return true;
        }
        const expireDate = new Date(this.credentials.Expiration);
        const now = new Date();
        // 如果有效期不足30分钟，视为过期。
        return expireDate.getTime() - now.getTime() <= 1800000;
    }
    // 上传的进度回调函数
    onProgress = (progress) => {
      eventBus.emit('refreshProgress',progress*100 );
    };
}
export default ClientSTS

