const formatDate=(timestamp) =>{
    if (!timestamp) {
        return ''; // 或返回一个默认值，例如 'N/A' 或其他可读字符串
    }
    const date = new Date(timestamp);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    // 返回你想要的自定义格式：yyyy-MM-dd HH:mm:ss
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}
const formatDate_simple=(timestamp) =>{
    if (!timestamp) {
        return ''; // 或返回一个默认值，例如 'N/A' 或其他可读字符串
    }
    const date = new Date(timestamp);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
}
export {formatDate,formatDate_simple};