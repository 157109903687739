
import * as THREE from 'three';
import { Tween, Easing } from '@tweenjs/tween.js';
import eventBus from '../js/eventBus.js';
const CanvasOperation = class {
    constructor(camera, scene, renderer, controls) {
        this.camera = camera;
        this.scene = scene;
        this.renderer = renderer;
        this.controls = controls;
        this.timer = null;
        this.spriteDict = new Map();
        this.measure = null;
        this.animationFrameId = null;
        // 创建时钟以便于获取时间增量
        this.clock = new THREE.Clock();
        this.line = null;
        this.box_save = null;
        this.start = null;
        this.sprites = [];
        this.elbows = [];
        this.pickableObjects = [];
        this.centerscreen = new THREE.Vector2();
        this.target = new THREE.Vector2();
        this.panOffset = new THREE.Vector3();
        this.controlsTarget= controls.target;
        this.autoRotate=null;
        // 监听浏览器窗口变化
        window.addEventListener('resize', () => {
            this.camera.aspect = window.innerWidth / window.innerHeight;
            // 更新相机的投影矩阵
            this.camera.updateProjectionMatrix();
            // 调整渲染器的宽高
            this.renderer.setSize(window.innerWidth, window.innerHeight);
        })
        // 禁用右键菜单
        renderer.domElement.addEventListener('contextmenu', (event) => {
            event.preventDefault(); // 防止右键菜单显示
        });
    }
    //监听鼠标滚动
    MouseWheel = (event) => {
        event.preventDefault();
        const { camera, renderer, controls } = this;
        let factor = 1; // 控制相机缩放的速度，数值越大缩放越快
        if (event.deltaY < 0) {
            factor = 0.3;
        } else {
            factor = -0.3;
        }
        // 获取鼠标的屏幕坐标，转换为WebGL坐标范围[-1,1]
        const rect = renderer.domElement.getBoundingClientRect();
        const mouseX = ((event.clientX - rect.left) / rect.width) * 2 - 1;
        const mouseY = -((event.clientY - rect.top) / rect.height) * 2 + 1;
        // 将屏幕坐标转换为三维世界坐标
        const vector = new THREE.Vector3(mouseX, mouseY, 0.5); // z=0.5表示在相机的视锥中间
        vector.unproject(camera); // 从屏幕坐标到世界坐标的反投影
        vector.sub(camera.position).normalize(); // 计算相机到点击点的方向向量
        const distance = controls.target.distanceTo(camera.position); // 当前相机到target的距离
        const moveDistance = factor * distance * 0.08; // 缩放的移动距离
        // 计算相机和目标的新位置
        camera.position.addScaledVector(vector, moveDistance);
        if(!this.autoRotate){
        this.controlsTarget.addScaledVector(vector, moveDistance);
        camera.lookAt(this.controlsTarget);
        }
        // 每次滚动都会重置定时器
        if (this.timer) {
            clearTimeout(this.timer);  // 如果有现存的timer，先清除掉，避免重复调用
        }
        this.timer = setTimeout(() => {
            // 执行你的逻辑
            if (this.measure && this.spriteDict.size > 0) {
                for (const [sprite, canvas] of this.spriteDict.entries()) {
                    this.updateSpriteSize(sprite, canvas);
                }
            }
            eventBus.emit("changeflag")
        }, 100); // 100ms后执行
        
    };
    onMouseMove_right(event, startMousePosition) {
        // 计算鼠标移动的距离
        const panDelta = new THREE.Vector2();
        const panEnd = new THREE.Vector2(event.x, event.y);
        //计算移动向量
        panDelta.subVectors(panEnd, startMousePosition).multiplyScalar(0.5);
        this.Pan(panDelta.x, panDelta.y);
        eventBus.emit("changeflag")
    }
    Pan(deltaX, deltaY) {
        // 获取渲染器 DOM 的宽高，用于计算平移距离的比例
        const element = this.renderer.domElement;
        // 求取相机距离目标点的距离
        const offset = new THREE.Vector3().copy(this.camera.position).sub(this.controls.target); // 相机到目标点的矢量
        const targetDistance = offset.length();
        // 用相机的视角 (FOV) 来调整平移的距离
        const effectiveDistance = targetDistance * Math.tan((this.camera.fov / 2) * Math.PI / 180.0);
        // 平移比例因子，x 和 y 表示不同方向上的比例
        const panX = (2 * deltaX * effectiveDistance) / element.clientHeight;
        const panY = (2 * deltaY * effectiveDistance) / element.clientHeight;

        // 调用 panLeft 和 panUp 方法完成相机平移
        this.panLeft(panX, this.camera.matrix);
        this.panUp(panY, this.camera.matrix);
    }
    panLeft(distance, objectMatrix) {
        const v = new THREE.Vector3(); // 用于存储方向向量
        v.setFromMatrixColumn(objectMatrix, 0); // 获取相机的 X 轴方向（向右）
        v.multiplyScalar(-distance); // 按平移的距离调整方向
        this.camera.position.add(v); // 更新相机位置
        // this.controls.target.add(v); // 同时平移目标点，保持相机与目标点的相对位置
        this.controlsTarget.add(v);
        this.camera.lookAt(this.controlsTarget);
    }
    panUp(distance, objectMatrix) {
        const v = new THREE.Vector3(); // 用于存储方向向量
        v.setFromMatrixColumn(objectMatrix, 1); // 获取相机的 Y 轴方向（向上）
        v.multiplyScalar(distance); // 按平移的距离调整方向
        this.camera.position.add(v); // 更新相机位置
        this.controlsTarget.add(v);
        this.camera.lookAt(this.controlsTarget); // 同步平移目标点
    }
    // 获取双指间的距离
    getTouchDistance(event) {
        const dx = event.touches[0].clientX - event.touches[1].clientX;
        const dy = event.touches[0].clientY - event.touches[1].clientY;
        return Math.sqrt(dx * dx + dy * dy);
    }
    // 获取双指连线的中心位置
    getTouchCenter(event) {

        const rect = this.renderer.domElement.getBoundingClientRect();
        const mouseX = ((event.x - rect.left) / rect.width) * 2 - 1;
        const mouseY = -((event.y - rect.top) / rect.height) * 2 + 1;
        // 将屏幕坐标转换为三维世界坐标
        const vector = new THREE.Vector3(mouseX, mouseY, 0.5); // z=0.5表示在相机的视锥中间
        vector.unproject(this.camera); // 从屏幕坐标到世界坐标的反投影
        return vector;
    }

   
    throttle(func, limit) {
        let lastFunc;
        let lastRan;
        return function (...args) {
            const context = this;
            if (!lastRan) {
                func.apply(context, args);
                lastRan = Date.now();
            } else {
                clearTimeout(lastFunc);
                lastFunc = setTimeout(function () {
                    if ((Date.now() - lastRan) >= limit) {
                        func.apply(context, args);
                        lastRan = Date.now();
                    }
                }, limit - (Date.now() - lastRan));
            }
        };
    }
    Centermouseraycaster = () => {
        const px = window.innerWidth / 2;
        const py = window.innerHeight / 2;
        const x = (px / window.innerWidth) * 2 - 1;
        const y = -(py / window.innerHeight) * 2 + 1;
        //创建一个射线投射器`Raycaster`
        const raycaster = new THREE.Raycaster();
        // 通过摄像机和鼠标位置更新射线
        raycaster.setFromCamera(new THREE.Vector2(x, y), this.camera);
        const intersects = raycaster.intersectObjects(this.pickableObjects);
        return intersects;
    }
    // 射线拾取选择场景模型表面任意点xyz坐标
    rayChoosePoint(event) {
        const px = event.offsetX;
        const py = event.offsetY;
        //屏幕坐标转标准设备坐标
        const x = (px / window.innerWidth) * 2 - 1;
        const y = -(py / window.innerHeight) * 2 + 1;
        const raycaster = new THREE.Raycaster();
        //.setFromCamera()在点击位置生成raycaster的射线ray
        raycaster.setFromCamera(new THREE.Vector2(x, y), this.camera);
        let v3 = null;
        const intersects = raycaster.intersectObjects(this.pickableObjects);
        if (intersects.length > 0) {
            const selected = this.clip_clickObject(intersects)
            if (selected) {
                v3 = selected.point;
            }

        }
        return v3;
    }
    rayChooseObject(event) {
        const px = event.offsetX;
        const py = event.offsetY;
        //屏幕坐标转标准设备坐标
        const x = (px / window.innerWidth) * 2 - 1;
        const y = -(py / window.innerHeight) * 2 + 1;
        const raycaster = new THREE.Raycaster();
        //.setFromCamera()在点击位置生成raycaster的射线ray
        raycaster.setFromCamera(new THREE.Vector2(x, y), this.camera);
        let selected = null;
        const intersects = raycaster.intersectObjects(this.pickableObjects);
        if (intersects.length > 0) {
            selected = this.clip_clickObject(intersects)
            if (selected) {
                return selected.object;
            }
        }


    }
    // 计算模型上选中两点的距离
    length(p1, p2) {
        return p1.clone().sub(p2).length()
    }
    // 两点绘制一条直线 用于标注尺寸
    createLine(p1, p2) {
        const material = new THREE.LineBasicMaterial({
            color: 0xffff00,
            depthTest: false,//不进行深度测试，后渲染，叠加在其它模型之上(解决两个问题)
            // 1.穿过模型，在内部看不到线条
            // 2.线条与mesh重合时候，深度冲突不清晰
        });
        const geometry = new THREE.BufferGeometry(); //创建一个几何体对象
        //类型数组创建顶点数据
        const vertices = new Float32Array([p1.x, p1.y, p1.z, p2.x, p2.y, p2.z]);
        geometry.attributes.position = new THREE.BufferAttribute(vertices, 3);
        const line = new THREE.Line(geometry, material);
        return line
    }
    createMesh(p, dir, camera) {
        const L = camera.position.clone().sub(p).length()
        const h = L / 30
        //尺寸你可以根据需要自由设置，比如距离相机距离，比如直接根据场景渲染范围给固定尺寸
        const geometry = new THREE.CylinderGeometry(0, L / 200, h);
        geometry.translate(0, -h / 2, 0)
        const material = new THREE.MeshBasicMaterial({
            color: 0x00ffff, //设置材质颜色
            depthTest: false,
        });
        const mesh = new THREE.Mesh(geometry, material);
        //通过四元数表示默认圆锥需要旋转的角度，才能和标注线段的方向一致
        const quaternion = new THREE.Quaternion();
        //参数dir表示线段方向，通过两点p1、p2计算即可，通过dir来控制圆锥朝向
        quaternion.setFromUnitVectors(new THREE.Vector3(0, 1, 0), dir)
        mesh.quaternion.multiply(quaternion)
        mesh.position.copy(p);
        return mesh;
    }

    clip_clickObject = (intersects) => {
        let i = 0;
        let flag = false;
        if (this.box_save) {
            for (i = 0; i < intersects.length; i++) {
                let j = 0;
                for (const value of this.box_save.clipplaneMap.values()) {
                    const distanceToPlane = value.distanceToPoint(intersects[i].point);
                    if (distanceToPlane <= 0) {
                        break;
                    }
                    else {
                        j++;
                    }
                }
                if (j == this.box_save.clipplaneMap.size) {
                    //找到符合的mesh
                    flag = true;
                    break;
                }
            }
        }
        else {
            flag = true;
        }
        if (!flag) return;
        const selected = intersects[i];
        return selected
    }
    createCanvas(name) {
        /**
         * 创建一个canvas对象，绘制几何图案或添加文字
         */
        const canvas = document.createElement("canvas");
        const arr = name.split(""); //分割为单独字符串
        let num = 0;
        const reg = /[\u4e00-\u9fa5]/;
        for (let i = 0; i < arr.length; i++) {
            if (reg.test(arr[i])) { //判断是不是汉字
                num += 1;
            } else {
                num += 0.5; //英文字母或数字累加0.5
            }
        }
        // 根据字符串符号类型和数量、文字font-size大小来设置canvas画布宽高度
        const h = 240; //根据渲染像素大小设置，过大性能差，过小不清晰
        const w = h + num * 110;
        canvas.width = w;
        canvas.height = h;
        const h1 = h * 0.8;
        const c = canvas.getContext('2d');
        // 定义轮廓颜色，黑色半透明
        c.fillStyle = "rgba(0,0,0,0.6)";
        // 绘制半圆+矩形轮廓
        const R = h1 / 2;
        c.arc(R, R, R, -Math.PI / 2, Math.PI / 2, true); //顺时针半圆
        c.arc(w - R, R, R, Math.PI / 2, -Math.PI / 2, true); //顺时针半圆
        c.fill();
        // 绘制箭头
        c.beginPath();
        const h2 = h - h1;
        c.moveTo(w / 2 - h2 * 0.6, h1);
        c.lineTo(w / 2 + h2 * 0.6, h1);
        c.lineTo(w / 2, h);
        c.fill();
        // 文字
        c.beginPath();
        c.translate(w / 2, h1 / 2);
        c.fillStyle = "#ffffff"; //文本填充颜色
        c.font = "normal 128px Times New Roman"; //字体样式设置
        c.textBaseline = "middle"; //文本与fillText定义的纵坐标
        c.textAlign = "center"; //文本居中(以fillText定义的横坐标)
        c.fillText(name, 0, 0);
        return canvas;
    }
    updateSpriteSize(sprite, canvas) {
        const distance = this.camera.position.clone().distanceTo(sprite.position); // 相机到精灵的距离
        const scaleRatio = distance / 25; // 比例因子（依据需求调整距离）
        const y = 1 * scaleRatio; // 更新y方向尺寸
        const x = (canvas.width / canvas.height) * y; // 保持宽高比
        sprite.scale.set(x, y, 1); // 调整精灵尺寸
    }
    // 动画函数
    animateToCenter(targetPosition) {
        // 获取当前控制器的目标位置
        // const currentTarget = this.controls.target.clone();

        // 新目标位置
        const newTarget = targetPosition.clone();
        // 设置相机拉近的距离
        const distance = 4; // 根据需要调整此距离

        // 计算相机的目标位置
        const cameraDirection = newTarget.clone().sub(this.camera.position).normalize();
        const newCameraPosition = newTarget.clone().sub(cameraDirection.multiplyScalar(distance));
        // 创建 Tween 动画
        const tween = new Tween(this.camera.position)
            .to(
                {
                    x: newCameraPosition.x,
                    y: newCameraPosition.y,
                    z: newCameraPosition.z
                },
                1000 // 动画持续时间，单位毫秒
            )
            .easing(Easing.Quadratic.Out) // 缓动函数，可以选择不同的
            .onUpdate(() => {
                // 更新控制器目标位置
                this.camera.updateProjectionMatrix(); // 更新投影矩阵

            })
            .onComplete(() => {
                // 动画完成后，更新控制器目标位置
                this.controls.target.copy(newTarget);
                this.controls.update();
            })
            .start();
        // 创建 Tween 动画以平滑移动目标位置
        const targetTween = new Tween(this.controls.target)
            .to(
                {
                    x: newTarget.x,
                    y: newTarget.y,
                    z: newTarget.z
                },
                1000 // 动画持续时间，单位毫秒
            )
            .easing(Easing.Quadratic.Out) // 缓动函数
            .onUpdate(() => {
                this.controls.update(); // 更新控制器
            })
            .start()
        // 在动画循环中更新 Tweens
        function animate() {
            requestAnimationFrame(animate);
            tween.update(); // 更新 TWEEN 动画
            targetTween.update();
        }
        animate();
    }
    drawLineHandler = (event) => {
        const end = this.rayChoosePoint(event)
        if (!end) return;
        //第一次不能清除历史绘制
        if (this.line) {
            this.scene.remove(this.line);
            const meshleft = this.elbows.pop(); // 移除并获取最后一个元素
            const meshright = this.elbows.pop(); // 移除并获取倒数第二个元素
            this.scene.remove(meshleft);
            this.scene.remove(meshright);
            this.scene.remove(this.sprites.pop()); // 移除并获取最后一个精灵
        }

        if (end) {

            this.line = this.createLine(this.start, end)
            this.scene.add(this.line);
            this.createSprite(this.start, end)
        }

    }
    temp_remove = () => {
        this.scene.remove(this.line)
        this.line = null;
        this.chexiao()

    }
    chexiao() {
        const meshleft = this.elbows.pop(); // 移除并获取最后一个元素
        const meshright = this.elbows.pop(); // 移除并获取倒数第二个元素
        this.scene.remove(meshleft);
        this.scene.remove(meshright);
        this.scene.remove(this.sprites.pop()); // 移除并获取最后一个精灵
    }

    createSprite = (p1, p2) => {
        const dir = p1.clone().sub(p2).normalize();
        const meshleft = this.createMesh(p1, dir, this.camera);
        const meshright = this.createMesh(p2, dir.clone().negate(), this.camera)
        this.scene.add(meshleft);
        this.scene.add(meshright);
        const canvas = this.createCanvas(this.length(p1, p2).toFixed(2) + 'm')
        const texture = new THREE.CanvasTexture(canvas);
        const spriteMaterial = new THREE.SpriteMaterial({
            map: texture,
            depthTest: false,
        });
        const sprite = new THREE.Sprite(spriteMaterial);
        const center = p1.clone().add(p2).divideScalar(2)
        const y = this.camera.position.clone().sub(center).length() / 25;//精灵y方向尺寸
        // sprite宽高比和canvas画布保持一致
        const x = canvas.width / canvas.height * y;//精灵x方向尺寸
        sprite.scale.set(x, y, 1);// 控制精灵大小
        sprite.position.copy(center);
        sprite.position.y += y / 2;
        this.scene.add(sprite);
        this.spriteDict.set(sprite, canvas)//同步缩放
        this.elbows.push(meshleft);
        this.elbows.push(meshright)
        this.sprites.push(sprite); // 存储精灵引用

    }
    dispose() {
        // 移除精灵
        for (const sprite of this.sprites) {
            this.scene.remove(sprite);
        }
        this.sprites.length = 0; // 清空存储精灵的数组
        //移除箭头
        for (const elbow of this.elbows) {
            this.scene.remove(elbow);
        }
        this.elbows.length = 0;
    }
    measure_delete = (event) => {
        const sprite = this.rayChooseObject(event);
        console.log(sprite)
    }
}

export default CanvasOperation