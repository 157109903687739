<template>
    <div class="notice_out">
        <div class="notice_aa">
            <div class="notice_a">
                消息中心
            </div>
        </div>
        <div class="notice_bb">
            <el-tabs v-model="notice_activeName" class="notice_demo-tabs" @tab-change="handleClick">
                <el-tab-pane label="全部" name="first">
                    <noticeitem :noticelist="noticedata"></noticeitem>
                </el-tab-pane>
                <el-tab-pane label="未读" name="second">
                    <noticeitem :noticelist="noticedata"></noticeitem>
                </el-tab-pane>
                <el-tab-pane label="已读" name="thired">
                    <noticeitem :noticelist="noticedata"></noticeitem>
                </el-tab-pane>
            </el-tabs>
        </div>

    </div>

</template>
<script>
import "../css/notice.css"
import noticeitem from "./noticeitem.vue"
export default {
    name: 'NoticeA',
    props: {
        msg: String
    },
    components: {
        noticeitem,
    }

}
</script>
<script setup>
import { ref, onMounted,getCurrentInstance, reactive,onBeforeUnmount } from 'vue'
import { useStore } from 'vuex'
import handleError from '../config/HandleError.js'
import { getCookie } from '../store/cookieUtil.js';
import eventBus from '../js/eventBus.js';
const notice_activeName = ref('first');
const { proxy } = getCurrentInstance()
const store = useStore()
const noticedata=reactive([])
const user = JSON.parse(sessionStorage.getItem('user'))
const handleClick = (e) => {
    store.dispatch('noticeitemStatusfun', e);
};
onMounted(async() => {
    eventBus.on('loadData', loadData);
    await loadData()
})
onBeforeUnmount(() => {
    eventBus.off('loadData', loadData);
})
const loadData=async()=>{
    const urldata = new URLSearchParams();
    urldata.append('Token', getCookie('token'));
    urldata.append('Account', user.emailname);
    const res = await new proxy.$request(proxy.$urls.m().GetInforms, urldata).modepost();
    if (!res) return;
    if (res.status != 200) {
        new proxy.$tips(res.data.message, "warning").Mess_age();
        return;
    }
    if (res.data && res.data.Error === 0) {
        noticedata.splice(0, noticedata.length, ...res.data.informInfo);
    } else {
        const errorMessage = handleError(res);
        new proxy.$tips(errorMessage, "error").Mess_age();
    }
}
</script>