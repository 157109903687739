
import * as THREE from 'three';
// import MATERIAL from "../js/material.js";
import UNIFORMS from '../js/uniforms.js'
import SelectionBoxFace from '../js/selectionBoxFace.js';
import SelectionBoxLine from '../js/selectionBoxLine.js';
class Selection {
    constructor(low, high, model) {
        this.limitLow = low;//当前盒子大小
        this.limitHigh = high;
        const box = model;//完整盒子大小
        this.temp_low = box.min.clone();
        this.temp_high = box.max.clone();
        // this.box = new THREE.BoxGeometry(1, 1, 1);
        // this.boxMesh = new THREE.Mesh(this.box, MATERIAL.cap);
        this.vertices = [
            new THREE.Vector3(), new THREE.Vector3(),
            new THREE.Vector3(), new THREE.Vector3(),
            new THREE.Vector3(), new THREE.Vector3(),
            new THREE.Vector3(), new THREE.Vector3()
        ];
        //初始化端点
        this.updateVertices();

        var v = this.vertices;

        this.touchMeshes = new THREE.Object3D();
        this.displayMeshes = new THREE.Object3D();
        this.meshGeometries = [];
        this.lineGeometries = [];
        this.selectables = [];
        this.faces = [];
        var f = this.faces;
        this.faces.push(new SelectionBoxFace('y1', v[0], v[1], v[5], v[4], this));
        this.faces.push(new SelectionBoxFace('z1', v[0], v[2], v[3], v[1], this));
        this.faces.push(new SelectionBoxFace('x1', v[0], v[4], v[6], v[2], this));

        this.faces.push(new SelectionBoxFace('x2', v[7], v[5], v[1], v[3], this));
        this.faces.push(new SelectionBoxFace('y2', v[7], v[3], v[2], v[6], this));
        this.faces.push(new SelectionBoxFace('z2', v[7], v[6], v[4], v[5], this));
        new SelectionBoxLine(v[0], v[1], f[0], f[1], this);
        new SelectionBoxLine(v[0], v[2], f[1], f[2], this);
        new SelectionBoxLine(v[0], v[4], f[0], f[2], this);
        new SelectionBoxLine(v[1], v[3], f[1], f[3], this);
        new SelectionBoxLine(v[1], v[5], f[0], f[3], this);
        new SelectionBoxLine(v[2], v[3], f[1], f[4], this);
        new SelectionBoxLine(v[2], v[6], f[2], f[4], this);
        new SelectionBoxLine(v[3], v[7], f[3], f[4], this);
        new SelectionBoxLine(v[4], v[5], f[0], f[5], this);
        new SelectionBoxLine(v[4], v[6], f[2], f[5], this);
        new SelectionBoxLine(v[5], v[7], f[3], f[5], this);
        new SelectionBoxLine(v[6], v[7], f[4], f[5], this);
        this.setBox();
        this.setUniforms();
    }

    updateVertices() {
        this.vertices[0].set(this.limitLow.x, this.limitLow.y, this.limitLow.z);
        this.vertices[1].set(this.limitHigh.x, this.limitLow.y, this.limitLow.z);
        this.vertices[2].set(this.limitLow.x, this.limitHigh.y, this.limitLow.z);
        this.vertices[3].set(this.limitHigh.x, this.limitHigh.y, this.limitLow.z);
        this.vertices[4].set(this.limitLow.x, this.limitLow.y, this.limitHigh.z);
        this.vertices[5].set(this.limitHigh.x, this.limitLow.y, this.limitHigh.z);
        this.vertices[6].set(this.limitLow.x, this.limitHigh.y, this.limitHigh.z);
        this.vertices[7].set(this.limitHigh.x, this.limitHigh.y, this.limitHigh.z);
    }

    updateGeometries() {
        // 更新meshGeometries

        const verticesToUpdate = [
            this.vertices[0],
            this.vertices[1],
            this.vertices[5],
            this.vertices[4],
            this.vertices[0],
            this.vertices[1],
            this.vertices[5],
            this.vertices[4],

            this.vertices[0],
            this.vertices[2],
            this.vertices[3],
            this.vertices[1],
            this.vertices[0],
            this.vertices[2],
            this.vertices[3],
            this.vertices[1],

            this.vertices[0],
            this.vertices[4],
            this.vertices[6],
            this.vertices[2],
            this.vertices[0],
            this.vertices[4],
            this.vertices[6],
            this.vertices[2],

            this.vertices[7],
            this.vertices[5],
            this.vertices[1],
            this.vertices[3],
            this.vertices[7],
            this.vertices[5],
            this.vertices[1],
            this.vertices[3],

            this.vertices[7],
            this.vertices[3],
            this.vertices[2],
            this.vertices[6],
            this.vertices[7],
            this.vertices[3],
            this.vertices[2],
            this.vertices[6],

            this.vertices[7],
            this.vertices[6],
            this.vertices[4],
            this.vertices[5],
            this.vertices[7],
            this.vertices[6],
            this.vertices[4],
            this.vertices[5],

        ];
        let k = 0;
        for (var i = 0; i < this.meshGeometries.length; i++) {
            const meshGeometry = this.meshGeometries[i].attributes.position.array;

            for (let j = k; j < verticesToUpdate.length; j++) {
                const vertex = verticesToUpdate[j];
                meshGeometry.set([
                    vertex.x,
                    vertex.y,
                    vertex.z
                ], 3 * (k % 4)); // 每个顶点占用3个位置
                k++;
                if (k % 4 == 0) break;
            }
            this.meshGeometries[i].attributes.position.needsUpdate = true
            this.meshGeometries[i].computeBoundingSphere();
            this.meshGeometries[i].computeBoundingBox();
        }
        // const verticesCopy = this.vertices.map(vertex => vertex.clone());
        // verticesCopy[0]=new THREE.Vector3(verticesCopy[0].x-0.01,verticesCopy[0].y-0.01,verticesCopy[0].z-0.01)
        // verticesCopy[1]=new THREE.Vector3(verticesCopy[1].x+0.01,verticesCopy[1].y-0.01,verticesCopy[1].z-0.01)
        // verticesCopy[2]=new THREE.Vector3(verticesCopy[2].x-0.01,verticesCopy[2].y+0.01,verticesCopy[2].z-0.01)
        // verticesCopy[3]=new THREE.Vector3(verticesCopy[3].x+0.01,verticesCopy[3].y+0.01,verticesCopy[3].z-0.01)
        // verticesCopy[4]=new THREE.Vector3(verticesCopy[4].x-0.01,verticesCopy[4].y-0.01,verticesCopy[4].z+0.01)
        // verticesCopy[5]=new THREE.Vector3(verticesCopy[5].x+0.01,verticesCopy[5].y-0.01,verticesCopy[5].z+0.01)
        // verticesCopy[6]=new THREE.Vector3(verticesCopy[6].x-0.01,verticesCopy[6].y+0.01,verticesCopy[6].z+0.01)
        // verticesCopy[7]=new THREE.Vector3(verticesCopy[7].x+0.01,verticesCopy[7].y+0.01,verticesCopy[7].z+0.01)
        const verticesToUpdate2 = [
            this.vertices[0],
            this.vertices[1],
            this.vertices[0],
            this.vertices[2],
            this.vertices[0],
            this.vertices[4],
            this.vertices[1],
            this.vertices[3],
            this.vertices[1],
            this.vertices[5],
            this.vertices[2],
            this.vertices[3],
            this.vertices[2],
            this.vertices[6],
            this.vertices[3],
            this.vertices[7],
            this.vertices[4],
            this.vertices[5],
            this.vertices[4],
            this.vertices[6],
            this.vertices[5],
            this.vertices[7],
            this.vertices[6],
            this.vertices[7],
            this.vertices[0],
            this.vertices[2],
            this.vertices[0],
            this.vertices[4],
        ];
        let k2 = 0;
        for (var i2 = 0; i2 < this.lineGeometries.length; i2++) {
            const lineGeometry = this.lineGeometries[i2].attributes.position.array;

            for (var j2 = k2; j2 < verticesToUpdate2.length; j2++) {
                const vertex = verticesToUpdate2[j2];
                lineGeometry.set([
                    vertex.x,
                    vertex.y,
                    vertex.z

                ], 3 * (k2 % 2)); // 每个顶点占用3个位置
                k2++;
                if (k2 % 2 == 0) break;

            }
            this.lineGeometries[i2].attributes.position.needsUpdate = true
            this.lineGeometries[i2].computeBoundingSphere();
        }



    }

    setBox() {
        var width = new THREE.Vector3();
        width.subVectors(this.limitHigh, this.limitLow);
        // this.boxMesh.geometry.dispose(); // 清除旧的几何体
        // this.boxMesh.geometry = new THREE.BoxGeometry(width.x, width.y, width.z); // 创建新的几何体
        // this.boxMesh.position.copy(this.limitLow.clone().add(width.multiplyScalar(0.5))); // 设置位置为新框的中心
    }

    setUniforms() {
        var uniforms = UNIFORMS.clipping;
        uniforms.clippingLow.value.copy(this.limitLow);
        uniforms.clippingHigh.value.copy(this.limitHigh);
    }

    setValue(axis, value) {
        var buffer = 0.4;
        // var limit = 25;
        console.log(this.temp_low, this.temp_high)
        if (axis === 'x1') {
            this.limitLow.x = Math.max(this.temp_low.x, Math.min(this.limitHigh.x - buffer, value));
        } else if (axis === 'x2') {
            this.limitHigh.x = Math.max(this.limitLow.x + buffer, Math.min(this.temp_high.x, value));
        } else if (axis === 'y1') {
            this.limitLow.y = Math.max(this.temp_low.y, Math.min(this.limitHigh.y - buffer, value));
        } else if (axis === 'y2') {
            this.limitHigh.y = Math.max(this.limitLow.y + buffer, Math.min(this.temp_high.y, value));
        } else if (axis === 'z1') {
            this.limitLow.z = Math.max(this.temp_low.z, Math.min(this.limitHigh.z - buffer, value));

        } else if (axis === 'z2') {
            this.limitHigh.z = Math.max(this.limitLow.z + buffer, Math.min(this.temp_high.z, value));
        }


        this.setBox();
        this.setUniforms();
        this.updateVertices();
        this.updateGeometries();
    }
}

// 导出 Selection 类
export default Selection;
